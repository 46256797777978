import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AlumniDrawer from './AlumniDrawer';
import BDCH from './Group 1075.png';

function AlumniNavbar() {
  const [isMobile, setIsMobile] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const isActive = (path) => location.pathname === path;

  const NavButton = ({ to, children, external }) => {
    const baseClasses = 'px-4 py-2 text-lg font-lora transition-all duration-200';
    const activeClasses = 'bg-white text-[#2360BD] rounded-md';
    const inactiveClasses = 'text-white hover:bg-white/10 rounded-md';

    if (external) {
      return (
        <Link
          onClick={() => window.open(to, '_blank')}
          className={`${baseClasses} ${isActive(to) ? activeClasses : inactiveClasses}`}
        >
          {children}
        </Link>
      );
    }

    return (
      <Link to={to} className={`${baseClasses} ${isActive(to) ? activeClasses : inactiveClasses}`}>
        {children}
      </Link>
    );
  };

  return (
    <div className="relative">
      {/* Banner Image */}
      <div className="w-full">
        <img src={BDCH} alt="Alumni" className="block w-full h-auto" />
      </div>

      {/* Navigation Bar */}
      <nav className="bg-gradient-to-r from-[#102C57] to-[#2360BD] relative">
        <div className={`py-2 ${isMobile ? 'flex justify-end' : 'flex justify-center'}`}>
          {isMobile ? (
            <AlumniDrawer />
          ) : (
            <div className="flex items-center space-x-2">
              <NavButton to="/alumni">Home</NavButton>
              <NavButton to="/alumni/directory">Directory</NavButton>
              <NavButton to="/alumni/membership">Online Life Membership</NavButton>
              <NavButton to="/alumni/news">News And Events</NavButton>
              <NavButton to="/alumni/contact">Contact Us</NavButton>
              <NavButton to="https://journals.lww.com/ijoh/pages/default.aspx" external>
                Journal
              </NavButton>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
}

export default AlumniNavbar;
