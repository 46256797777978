import React, { useEffect, useState } from 'react';
import pdf from './Facilities and Services.pdf';

function Services() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const openPDF = (pdfUrl) => {
    const win = window.open();
    win.document.write(
      `<iframe src="${pdfUrl}" style="width:100%; height:100%; border:none;"></iframe>`
    );
  };

  return (
    <div className="flex items-center justify-center w-full mt-5">
      <div className={`w-full ${isMobile ? 'overflow-x-auto' : 'overflow-x-hidden'}`}>
        {pdf &&
          (isMobile ? (
            <button
              onClick={() => openPDF(pdf)}
              className="px-6 py-3 bg-[#0352A1] text-white rounded-lg shadow-md hover:bg-[#0352A1]/90 transition-colors duration-200 font-garamond text-lg"
            >
              Facilities and Services?
              <br />
              Please Click here
            </button>
          ) : (
            <div className="flex items-center justify-center p-4 bg-white shadow-lg rounded-xl">
              <iframe
                title="Library PDF"
                src={pdf}
                className="w-full h-[1000px] rounded-lg"
                style={{
                  border: 'none',
                  boxSizing: 'border-box'
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default Services;
