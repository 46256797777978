import React, { useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import logo from '../header.png';
import imagee1 from './bapujibg.jpg';
import imagee2 from './ss.png';
import imagee3 from './ssm.png';
import imagee4 from './pres.jpeg';
import imagee5 from './vc.jpeg';
import VerticalScroll from './VerticalScroll'; // Import the VerticalScroll component
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import image1 from './ashok.jpeg';
import image2 from './prashanth.jpeg';
import CustomCard from './Cards.jsx';
import image3 from './pujac.jpeg';
import image4 from './trivenimg.jpeg';
import image5 from './shivaprasad.jpeg';
import image6 from './ahmedmujid.jpeg';
import image7 from './dhanyakumar.jpeg';
import image8 from './pres.jpeg';
import image9 from './lawrence.jpeg';
import image10 from './hckirankumar.jpeg';
import image11 from './bh_dhanyakumar.jpeg';
import image12 from './atprakash.jpeg';
import image13 from './raisonthomas.jpeg';
import image14 from './shambulingappa.jpeg';
import image15 from './vc.jpeg';
import image16 from './shashikanth.jpeg';
import image17 from './barontaarun.jpeg';
import image18 from './aliim.jpeg';
import image19 from './sangeethasiddabasappa.jpeg';
import image20 from './sunithan.jpeg';
import image21 from './dr_nandini.jpg';
import image22 from './dr_suresh_sajjan.png';
import image23 from './dr_gayatri_gv.jpg';
import image24 from './dr_mallikarjun.JPG';
import image25 from './dr_sowmya.JPG';
import image26 from './dr_arun.JPG';
import image27 from './dr_deepak.JPG';
import image28 from './dr_jnanashree.JPG';
import HorizontalNews from 'app/views/home/HorizontalNews';
import colorConfig2 from 'app/color/colorConfig2';
const theme = createTheme({
  palette: {
    primary: {
      // main: '#fdc735',
      main: '#750E21'
    }
  }
});

const buttonStyles = {
  color: 'white',
  fontSize: '18px',
  '&:hover': {
    color: '#69b6b1',
    backgroundColor: 'transparent'
  }
};

function AlumniHome2(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const alumniData = [
    // Leadership Positions
    { image: image8, name: 'Dr. D.B. Nandeeshwar', designation: 'President' },
    // { image: image18, name: 'Dr. Ali I.M.', designation: 'Vice President' },
    { image: image15, name: 'Dr. Shashidhara H.S.', designation: 'Vice-President' },
    { image: image17, name: 'Dr. Baron Tarun Kumar', designation: 'Honorary Secretary' },
    { image: image5, name: 'Dr. Shivaprasad S.', designation: 'Honorary Joint Secretary' },
    { image: image12, name: 'Dr. A.T. Prakash', designation: 'Honorary Treasurer' },
    { image: image19, name: 'Dr. Sangeeth Siddabasappa', designation: 'Editor in Chief' },
    { image: image3, name: 'Dr Puja C. Yavagal', designation: 'Associate Editor' },
    { image: image21, name: 'Dr Nandini T.N.', designation: 'Assistant Editor' },
    { image: image22, name: 'Dr. Suresh Sajjan', designation: 'Executive Committee Member' },
    { image: image1, name: 'Dr. Ashok L.', designation: 'Executive Committee Member' },
    { image: image18, name: 'Dr. Ali I.M.', designation: 'Executive Committee Member' },
    // { image: image20, name: 'Dr. Sunitha N. Shamnur', designation: 'Editor in Chief' },
    { image: image14, name: 'Dr. Shambulingappa P.', designation: 'Executive Committee Member' },
    { image: image23, name: 'Dr. Gayathri G.V.', designation: 'Executive Committee Member' },
    { image: image6, name: 'Dr. Ahmed Mujib B.R.', designation: 'Executive Committee Member' },
    { image: image24, name: 'Dr. Mallikarjun Goud K', designation: 'Executive Committee Member' },
    { image: image11, name: 'Dr. Dhanya Kumar B.H.', designation: 'Executive Committee Member' },
    { image: image2, name: 'Dr. Prashanth G.M', designation: 'Executive Committee Member' },
    { image: image10, name: 'Dr. Kiran Kumar H.C.', designation: 'Executive Committee Member' },
    { image: image13, name: 'Dr. Raison Thomas', designation: 'Executive Committee Member' },
    { image: image25, name: 'Dr. Sowmya N.K.', designation: 'Executive Committee Member' },
    { image: image26, name: 'Dr. Arun J.', designation: 'Executive Committee Member' },
    { image: image27, name: 'Dr. Deepak B.M', designation: 'Executive Committee Member' },
    { image: image9, name: 'Dr. Denzy Lawrence', designation: 'Executive Committee Member' },
    { image: image28, name: 'Dr. Jnanashree', designation: 'Executive Committee Member' }

    // Executive Committee Members
  ];

  const isMobile = useMediaQuery('(max-width:600px)');

  const CustomLeftArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      className="absolute z-10 flex items-center justify-center w-10 h-10 text-white transition-all duration-300 -translate-y-1/2 rounded-full left-2 top-1/2 bg-white/10 hover:bg-white/20"
      aria-label="Previous slide"
    >
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    </button>
  );

  const CustomRightArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      className="absolute z-10 flex items-center justify-center w-10 h-10 text-white transition-all duration-300 -translate-y-1/2 rounded-full right-2 top-1/2 bg-white/10 hover:bg-white/20"
      aria-label="Next slide"
    >
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </button>
  );
  let colorConfigs = colorConfig2;
  return (
    <div>
      {/* <HorizontalNews colorConfig={colorConfigs} setIsLoading={setIsLoading} alumninews={true} /> */}
      <div
        style={{
          position: 'relative',
          backgroundImage: `url(${imagee1})`,
          backgroundPosition: 'center',
          height: isMobile ? '150vh' : '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '0px',
          backgroundSize: 'cover'
        }}
      >
        {/* {isMobile && ( */}
        <div
          style={{
            position: 'absolute',
            top: '10%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textAlign: 'center',
            zIndex: 1,
            marginTop: isMobile ? '-60px' : '5px'
          }}
          className="font-ebgaramond"
        >
          <h1>PATRONS</h1>
        </div>
        {/* )} */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 1, 0.5)'
          }}
        ></div>

        <Grid container justify="center" spacing={6}>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', flexDirection: 'column', marginBottom: '-20px' }}
              >
                <div className="relative text-center">
                  <img
                    src={imagee2}
                    alt="Image2"
                    className="w-full max-w-[350px] h-[400px] object-contain object-center brightness-80"
                  />
                  <div className="p-1 -mt-10 bg-white">
                    <p className="text-lg font-semibold leading-none text-red-600">
                      Dr. Shamanuru Shivashankarappa
                    </p>
                    <p className="-mt-2 text-sm font-normal leading-none text-black">
                      Hon. Secretary
                    </p>
                    <p className="-mt-2 text-sm font-normal leading-none text-black">
                      Bapuji Educational Association
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', flexDirection: 'column', marginBottom: '-20px' }}
              >
                <div className="relative text-center">
                  <img
                    src={imagee3}
                    alt="Image2"
                    className="w-full max-w-[350px] h-[400px] object-contain object-center brightness-80"
                  />
                  <div className="p-1 -mt-10 bg-white">
                    <p className="text-lg font-semibold leading-none text-red-600">
                      Shri S.S. Mallikarjun
                    </p>
                    <p className="-mt-2 text-sm font-normal leading-none text-black">
                      Hon. Joint Secretary
                    </p>
                    <p className="-mt-2 text-sm font-normal leading-none text-black">
                      Bapuji Educational Association
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div style={{ margin: '50px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div style={{ padding: '20px', textAlign: 'center' }}>
              <img
                src={imagee4}
                alt="president"
                style={{
                  width: '250px',
                  height: '300px',
                  display: 'block',
                  margin: '0 auto'
                }}
              />
              <Typography
                align="center"
                variant="h5"
                style={{ color: '#750e21', fontFamily: "'EB Garamond', serif" }}
              >
                PRESIDENT'S MESSAGE
              </Typography>
              <Typography
                align="center"
                style={{ fontSize: '20px', fontFamily: "'EB Garamond', serif" }}
              >
                DR. D. B. NANDEESHWAR
              </Typography>
            </div>
            <VerticalScroll
              content=" It is a pride privilege for every alumnus of this great institution
                which has given birth to excellent academicians, dedicated clinicians, renowned entrepreneurs,
                committed research scientists, versatile faculty and empowered dental education globally under the able leadership of various esteemed
                stalwarts of dentistry with highest administrative skills and extraordinary teachers who are moulding the character of our students.
                Each year, as we welcome new students to BDCH we are reminded of the great legacy left behind by our wonderful alumni.
                The Alumni Association will continue its mission to raise awareness, to grow its membership base, to assist with development of community based opportunities,
                and to promote exciting activities and events for alumni in the area and beyond. I request every alumnus to be the part of your Alma Mater and to serve for the betterment of association
                and further development activities in campus.
           "
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ padding: '20px', textAlign: 'center' }}>
              <img
                src={image17}
                alt="seceretary"
                style={{
                  width: '250px',
                  height: '300px',
                  display: 'block',
                  margin: '0 auto'
                }}
              />
              <Typography
                align="center"
                variant="h5"
                style={{ color: '#750e21', fontFamily: "'EB Garamond', serif" }}
              >
                HON.SECRETARY'S MESSAGE
              </Typography>
              <Typography
                align="center"
                style={{ fontSize: '20px', fontFamily: "'EB Garamond', serif" }}
              >
                Dr. Baron Tarun Kumar
              </Typography>
            </div>
            <VerticalScroll
              content=" As Secretary of the Bapuji Dental College Alumni Association, I consider it an honour to welcome you all to this alumni portal.
                It is indeed my earnest desire to bring the our alumni closer through this medium, and I truly believe this will reconnect all of us.
                We are the foremost brand ambassadors and can play a vital role in the development of our Alma mater with our ideas and suggestions.

                We wish to nurture a rich culture among the alumni which leads to building a strong and connected alumni network in our field of dental
                education and services. We have many initiatives planned for the coming years and many innovative projects in the offing to increase member benefits and
                interaction and promote student welfare. I on behalf of the Alumni Association also take this opportunity to call upon our all alumni
                wherever they are to partner with us and lend us your co-operation and assistance to deliver on this challenge and make this vision of a vibrant and happening Alumni Association a reality..
          "
            />
          </Grid>
        </Grid>

        <div className="bg-[#daf0f7] py-12 px-4 md:px-16">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 4,
                partialVisibilityGutter: 40
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 3,
                partialVisibilityGutter: 30
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                partialVisibilityGutter: 20
              }
            }}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            itemClass="px-4"
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {alumniData.map((alumni, index) => (
              <div
                key={index}
                className="group h-[400px] flex flex-col items-center justify-center"
              >
                <div className="relative p-2 overflow-hidden transition-all duration-300 rounded-lg bg-white/5 hover:bg-white/10 hover:shadow-xl">
                  <img
                    src={alumni.image}
                    alt={`${alumni.name}`}
                    className="w-[200px] h-[230px] object-cover rounded-lg transition-transform duration-300 
                      group-hover:scale-105"
                  />
                </div>
                <div className="mt-6 text-center">
                  <h3 className="text-xl font-semibold text-[#2360BD] font-ebgaramond">
                    {alumni.name}
                  </h3>
                  <p className="mt-1 text-lg text-[#2360BD] font-ebgaramond">
                    {alumni.designation}
                  </p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '20px 10px',
          textAlign: 'center',
          marginLeft: isMobile ? '20px' : '25px'
        }}
      >
        <Grid container spacing={2} style={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={4}>
            <CustomCard
              sx={{ title: { color: '#C7B7A3' } }}
              title="MOTTO OF BEAUIDEAL"
              content={
                <div style={{ textAlign: 'left', fontSize: '20px' }} className="font-lora">
                  {''}
                  "Honoring the Past, Shaping the Future of Dentistry.
                  <br />
                  Empowering Excellence, Inspiring Innovation, Uniting Generations of Knowledge and
                  Care."
                </div>
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomCard
              sx={{
                title: { color: '#C7B7A3' },
                content: { textAlign: 'left' }
              }}
              title="CONTACT INFO"
              content={
                <div style={{ textAlign: 'left', fontSize: '17px' }} className="font-lora">
                  {' '}
                  Dr. Baron Tarun Kumar <br />
                  <br />
                  Professor,
                  <br /> Dept. of Periodontology <br />
                  Bapuji Dental College & Hospital <br />
                  Davangere - 577004
                  {/* Email: <a href="mailto:hebbalshashi@gmail.com">hebbalshashi@gmail.com</a> */}
                </div>
              }
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AlumniHome2;
