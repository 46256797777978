import React from 'react';
import DepButtons from './DepButtons';
import DepNameBar from './DepNameBar';
import departmentData from './departmentData.json';
import { useTheme, useMediaQuery, Grid, Typography, Divider } from '@mui/material';
import director from './image.png';
import { useParams } from 'react-router-dom';

import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import { useEffect } from 'react';
import colorConfig2 from 'app/color/colorConfig2';
import { useState } from 'react';
import { getFacultyDetailsByDept } from '../BackOfiice/components/UtilityFunctions';
import BDCH_GIF from 'assets/BDCH_GIF.gif';
import AnimatedDivider from './DeptHeading';

function HodMessage() {
  const { bigtext, textColor, smalltext, small, big } = colorConfig2;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [facultyArray, setFacultyArray] = useState([]);
  const [facultydata, setFacultyData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getFacultyDetailsByDept(setFacultyArray, id, setLoading);
  }, [id]);

  useEffect(() => {
    setFacultyData(facultyWithPriorityOne);
  }, [facultyArray]);

  const facultyWithPriorityOne = facultyArray.find(
    (facultyMember) => facultyMember.prioritywise === 1
  );

  const department = departmentData[id];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (!department) {
    return <div style={{ marginTop: '40vh' }}>Department not found</div>;
  }

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <img src={BDCH_GIF} alt="Loading..." />
      </div>
    );
  }

  return (
    <div className={` mx-auto ${isMobile ? 'mt-[8vh]' : 'mt-[28vh]'}`}>
      {isMobile ? <DepNameBar id={id} department={department} /> : null}
      {!isMobile && <AnimatedDivider message={`${department?.name} HOD`} />}

      <div className="flex flex-wrap justify-center pt-10">
        {/* Left side buttons */}
        {!isMobile && (
          <div className="w-full pr-5 md:w-1/6">
            <DepButtons id={id} />
          </div>
        )}

        {/* Main content */}
        <div className="w-full md:w-2/3">
          <div className="flex flex-col gap-8 md:flex-row">
            {/* Left side - Image and title */}
            <div className="flex flex-col items-center w-full md:w-1/3">
              <div className="w-[95%] relative p-[3px] rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 mb-4 bg-gradient-to-r from-[#0352A1] via-[#0180A6] to-[#00B4AC] ">
                <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-[#0352A1] via-[#0180A6] to-[#00B4AC] blur opacity-50"></div>
                <div className="relative bg-white rounded-xl">
                  <img
                    src={facultydata?.facProfile || director}
                    alt="HOD"
                    className="w-full rounded-xl"
                  />
                </div>
              </div>

              <div className="text-center font-ebgaramond p-4 bg-[#00B4AC]/10 rounded-lg w-[95%]">
                <h2 className="text-xl font-semibold mb-2 bg-gradient-to-r from-[#0352A1] via-[#0180A6] to-[#00B4AC] text-transparent bg-clip-text">
                  {department?.HODname}
                </h2>
                <p className="text-[#0180A6] text-base font-medium">
                  {id === 'ORAL_MEDICINE'
                    ? 'Professor & Head, Vice Principal'
                    : id === 'PROSTHODONTICS'
                    ? 'Professor & Head, Principal'
                    : 'Professor & Head'}
                </p>
              </div>
            </div>

            {/* Right side - Message content */}
            <div className="w-full font-lora px-4 md:w-2/3 md:px-0">
              {department?.HODMSG?.map((item, index) => (
                <div key={index} className={`mb-${item?.addSpace ? '5' : '0'}`}>
                  {item?.heading && (
                    <div
                      className={`text-left text-lg ${
                        item?.bold ? 'font-bold' : 'font-normal'
                      } text-[#112D58]`}
                    >
                      {item?.heading}
                    </div>
                  )}
                  <div
                    className={`text-justify text-base ${
                      item?.contentBold ? 'font-bold' : 'font-normal'
                    } text-[#2C3E50] font-georgia`}
                  >
                    {item?.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HodMessage;
