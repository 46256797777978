import React, { useState, useRef, useEffect } from 'react';

function VerticalScroll({ content, space = 20 }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const contentRef = useRef(null);
  const scrollSpeed = 1;

  useEffect(() => {
    let interval;
    const contentHeight = contentRef.current.scrollHeight / 2 + space;

    if (!isHovered) {
      interval = setInterval(() => {
        setScrollPosition((prevPosition) => {
          const newPosition = prevPosition + scrollSpeed;
          return newPosition >= contentHeight ? 0 : newPosition;
        });
      }, 50);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isHovered, scrollPosition, space]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.transform = `translateY(-${scrollPosition}px)`;
    }
  }, [scrollPosition]);

  return (
    <div
      style={{
        height: '254px',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        ref={contentRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0
        }}
        className="font-lora"
      >
        <div>
          {content.split('\n').map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
        <div style={{ marginTop: `${space}px` }}>
          {content.split('\n').map((line, index) => (
            <div key={index + content.length}>{line}</div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VerticalScroll;
