import React, { useEffect } from 'react';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import AHeader from '../components/AHeader';

const AcademicDetails = () => {
  const mdsContent = [
    { Slno: '1', Subject: 'Oral Medicine &  Radiology', Seat: '4' },
    { Slno: '2', Subject: 'Paedodontics & Preventive Dentistry', Seat: '6' },
    { Slno: '3', Subject: 'Public Health Dentistry', Seat: '3' },
    { Slno: '4', Subject: 'Oral Pathology & Microbiology', Seat: '2' },
    { Slno: '5', Subject: 'Orthodontics & Dentofacial Orthopedics', Seat: '6' },
    { Slno: '6', Subject: 'Conservative Dentistry & Endodontics', Seat: '6' },
    { Slno: '7', Subject: 'Oral & Maxillofacial Surgery', Seat: '6' },
    { Slno: '8', Subject: 'Periodontology', Seat: '6' },
    { Slno: '9', Subject: 'Prosthodontics and Crown & Bridge', Seat: '6' }
  ];

  const phdContent = [
    { Slno: '1', Subject: 'Oral Medicine & Radiology' },
    { Slno: '2', Subject: 'Perodontology' }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container px-4 mx-auto">
      <AHeader header={'ACADEMIC DETAILS'} />

      <div className="flex flex-col gap-8 lg:flex-row">
        <div className="w-full lg:w-full">
          <h2 className="text-3xl font-garamond text-center mb-8 text-[#0352A1]">
            INTAKE OF ADMISSIONS
          </h2>

          {/* First Table */}
          <div className="grid grid-cols-1 gap-6 mb-8 md:grid-cols-2 lg:grid-cols-4">
            {/* BDS Card */}
            <div className="p-6 transition-shadow duration-300 bg-white shadow-lg rounded-xl hover:shadow-xl">
              <h3 className="text-2xl font-garamond text-[#0352A1] mb-2">BDS</h3>
              <p className="text-lg text-gray-700 font-lora">Total Intake</p>
              <p className="text-3xl font-bold text-[#0352A1] mt-2">100 Seats</p>
            </div>

            {/* MDS Card */}
            <div className="p-6 transition-shadow duration-300 bg-white shadow-lg rounded-xl hover:shadow-xl">
              <h3 className="text-2xl font-garamond text-[#0352A1] mb-2">MDS</h3>
              <p className="text-lg text-gray-700 font-lora">Total Intake</p>
              <p className="text-3xl font-bold text-[#0352A1] mt-2">45 Seats</p>
            </div>

            {/* PhD Card */}
            <div className="p-6 transition-shadow duration-300 bg-white shadow-lg rounded-xl hover:shadow-xl">
              <h3 className="text-2xl font-garamond text-[#0352A1] mb-2">PhD</h3>
              <p className="text-lg leading-tight text-gray-700 font-lora">
                Research Programs Available
              </p>
            </div>

            {/* Implant Center Card */}
            <div className="p-6 transition-shadow duration-300 bg-white shadow-lg rounded-xl hover:shadow-xl">
              <h3 className="text-2xl font-garamond text-[#0352A1] mb-2">Implant Center</h3>
              <p className="text-sm text-gray-700 font-lora">
                INTERNATIONAL CONGRESS OF ORAL IMPLANTOLOGISTS COURSE (ICOI)
              </p>
              <a
                href="/assets/Bapuji Implantology Course Brochure.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 inline-block px-6 py-2 
                  bg-white text-[#0352A1] 
                  border-2 border-[#0352A1] 
                  rounded-lg text-lg font-semibold
                  hover:bg-[#0352A1] hover:text-white 
                  transition-all duration-300 
                  cursor-pointer 
                  shadow-md hover:shadow-lg"
              >
                Brochure
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-8 lg:flex-row">
            {/* MDS Section */}
            <div className="lg:w-2/3">
              <h3 className="text-2xl font-garamond text-[#0352A1] mb-4">MDS Programs</h3>
              <table className="min-w-full bg-white rounded-lg shadow-lg">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-4 text-lg text-[#0352A1] font-garamond">Sl. No</th>
                    <th className="px-6 py-4 text-lg text-[#0352A1] font-garamond">Subject</th>
                    <th className="px-6 py-4 text-lg text-[#0352A1] font-garamond">Seats</th>
                  </tr>
                </thead>
                <tbody>
                  {mdsContent.map((content, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 text-center font-lora">{index + 1}</td>
                      <td className="px-6 py-4 text-center font-lora">{content.Subject}</td>
                      <td className="px-6 py-4 text-center font-lora">{content.Seat}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* PhD Section */}
            <div className="lg:w-1/3">
              <h3 className="text-2xl font-garamond text-[#0352A1] mb-4">PhD Programs</h3>
              <table className="min-w-full bg-white rounded-lg shadow-lg">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-4 text-lg text-[#0352A1] font-garamond">Sl. No</th>
                    <th className="px-6 py-4 text-lg text-[#0352A1] font-garamond">Subject</th>
                  </tr>
                </thead>
                <tbody>
                  {phdContent.map((content, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 text-center font-lora">{index + 1}</td>
                      <td className="px-6 py-4 text-center font-lora">{content.Subject}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicDetails;
