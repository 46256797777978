import { Typography, Link, Divider } from '@mui/material';
import React from 'react';

const Forms = ({ formsData }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '30px'
      }}
    >
      <Link
        // href="/assets/admissionimages/BDS Application 24-25.pdf"
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        style={{ textDecoration: 'none' }}
      >
        <Typography
          variant="h4"
          style={{
            marginBottom: '10px',
            color: '#0352A1',
            marginTop: '20px',
            cursor: 'pointer'
          }}
        >
          Application Form for BDS
        </Typography>
        {/* <Typography sx={{ color: '#00B4AC' }}>click here...</Typography> */}
      </Link>

      <Divider
        sx={{
          marginY: '30px', // Vertical margin
          width: '40%' // Adjust width as needed
        }}
      />

      <Link
        // href="/assets/admissionimages/MDSApplication.pdf"
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        style={{ textDecoration: 'none' }}
      >
        <Typography
          variant="h4"
          style={{
            marginBottom: '10px',
            color: '#0352A1',
            marginTop: '20px',
            cursor: 'pointer'
          }}
        >
          Application Form for MDS
        </Typography>
        {/* <Typography sx={{ color: '#00B4AC' }}>click here...</Typography> */}
      </Link>
    </div>
  );
};

export default Forms;
