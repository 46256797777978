import React, { useState, useEffect } from 'react';
import Procedure from './Procedure';
import Forms from './Forms';
import Keycontact from './Keycontact';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import AHeader from '../components/AHeader';

const AdmissionPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const tabsData = [
    {
      id: 0,
      label: 'Application Procedures',
      procedure: {
        heading: 'ADMISSION PROCEDURE FOR BDS & MDS COURSES'
      }
    },
    {
      id: 1,
      label: 'Application Form',
      forms: {
        heading: 'af'
      }
    },
    {
      id: 2,
      label: 'Key Contact',
      keycontact: {
        heading: 'kc'
      }
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <AHeader header={'ADMISSIONS'} />

      <div className={`container mx-auto px-4 py-8 ${isMobile ? 'max-w-full' : 'max-w-7xl'}`}>
        <div className="flex flex-col gap-5 md:flex-row">
          {/* Main Content */}
          <div className="w-full md:w-3/4">
            <div className="overflow-hidden bg-white shadow-lg rounded-xl">
              {/* Tabs Navigation */}
              <div className="border-b border-gray-200">
                <div className={`flex ${isMobile ? 'flex-col' : ''} w-full`}>
                  {tabsData.map((tab, index) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`
                        flex-1 px-6 py-4 text-base font-medium font-garamond
                        transition-all duration-200
                        ${
                          activeTab === tab.id
                            ? `text-[#0180A6] bg-blue-50/50 
                               ${
                                 isMobile
                                   ? 'border-l-4 border-[#0180A6] rounded-r-xl'
                                   : 'border-b-4 border-[#0180A6]'
                               }`
                            : `text-gray-500 
                               ${
                                 isMobile
                                   ? 'border-l-4 border-transparent'
                                   : 'border-b-4 border-transparent'
                               }
                               hover:text-[#0180A6] hover:bg-blue-50/30`
                        }
                        ${!isMobile && index === 0 ? 'rounded-tl-xl' : ''}
                        ${!isMobile && index === tabsData.length - 1 ? 'rounded-tr-xl' : ''}
                      `}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>
              </div>

              {/* Tab Content */}
              <div className="p-6">
                {tabsData.map(
                  (tab) =>
                    activeTab === tab.id && (
                      <div key={tab.id} className="animate-fadeIn">
                        {tab.procedure && <Procedure procedureData={tab.procedure} />}
                        {tab.forms && <Forms formsData={tab.forms} />}
                        {tab.keycontact && <Keycontact keycontactData={tab.keycontact} />}
                      </div>
                    )
                )}
              </div>
            </div>
          </div>

          {/* News Banner */}
          {!isMobile && (
            <div className="w-full md:w-1/4">
              <VerticalNewsBanner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdmissionPage;
