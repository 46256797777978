import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';

function ResponsiveCard({ cover, title, description, colorConfig }) {
  const { backgroundColor, textColor, bigtext, big, small } = colorConfig;
  return (
    <Card
      style={{
        height: '100%',
        minHeight: 650,
        backgroundColor: backgroundColor,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <CardActionArea
        style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <CardMedia
          component="img"
          image={cover}
          alt={title}
          style={{
            objectFit: 'cover',
            height: '250px',
            flexShrink: 0 // Prevents image from shrinking
          }}
        />
        <CardContent
          style={{
            flex: 1,
            overflow: 'auto',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{
              color: big,
              fontWeight: 'bold',
              fontFamily: "'EB Garamond', serif",
              marginBottom: '12px',
              flexShrink: 0 // Keeps title from shrinking
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: small,
              fontFamily: 'Lora, serif',
              flex: 1,
              overflow: 'auto'
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ResponsiveCard;
