import AboutUs from './aboutus/AboutUs';
import Prospectus from './aboutus/Prospectus';
import AcademicDetails from './academic-affairs/AcademicDetails';
import Admission from './academic-affairs/Admission';
import ExamSchedule from './academic-affairs/ExamSchedule';
import Hostel from './academic-affairs/Hostel';
import Library from './academic-affairs/Library';
import Students from './academic-affairs/Students';
import Affiliation from './administration/Affiliation';
import Committees from './administration/Committees';
import Director from './administration/Director';
import Management from './administration/Management';
import Principal from './administration/Principal';
import Rti from './administration/Rti';
import Contact from './contact/Contact';
import Activities from './departments/Activities';
import Awards from './departments/Awards';
import Dissertation from './departments/Dissertation';
import Faculty from './departments/Faculty';
import HodMessage from './departments/HodMessage';
import Overview from './departments/Overview';
import Presentation from './departments/Presentation';
import Publications from './departments/Publications';
import Syllabus from './departments/DepartmentSyllabus';
import Gallery from './departments/DepartmentGallery';

import News from './news/News';
import Vision from './vision/Vision';
import AlumniGallery from './alumni/alumniGallery/AlumniGallery';
import Syllabus1 from './Syllabus/Syllabus';
import VicePrincipalMessage from './home/VicePrincipalMessage';

const { default: HomePage } = require('./home/HomePage');

const navBarRoutes = [
  // bapuji
  { path: '/', element: <HomePage /> },
  { path: '/aboutus', element: <AboutUs /> },
  { path: '/aboutus/prospectus', element: <Prospectus /> },
  { path: '/administration/affiliation', element: <Affiliation /> },
  { path: '/administration/committees', element: <Committees /> },
  { path: '/administration/management', element: <Management /> },
  { path: '/administration/principal', element: <Principal /> },
  { path: '/administration/viceprincipal', element: <VicePrincipalMessage /> },
  { path: '/administration/director', element: <Director /> },
  { path: '/administration/rti', element: <Rti /> },

  // Alumni
  { path: '/academic-affairs/academic-details', element: <AcademicDetails /> },
  { path: '/academic-affairs/exam-schedule', element: <ExamSchedule /> },
  { path: '/academic-affairs/admission', element: <Admission /> },
  { path: '/academic-affairs/hostel', element: <Hostel /> },
  { path: '/academic-affairs/library', element: <Library /> },
  { path: '/academic-affairs/students', element: <Students /> },

  { path: '/departments/overview/:id', element: <Overview /> },
  { path: '/departments/hod/:id', element: <HodMessage /> },
  { path: '/departments/faculty/:id', element: <Faculty /> },
  { path: '/departments/activities/:id', element: <Activities /> },
  { path: '/departments/awards/:id', element: <Awards /> },
  { path: '/departments/publications/:id', element: <Publications /> },
  { path: '/departments/presentation/:id', element: <Presentation /> },
  { path: '/departments/dissertation/:id', element: <Dissertation /> },
  { path: '/departments/syllabus/:id', element: <Syllabus /> },
  { path: '/departments/gallery/:id', element: <Syllabus /> },

  { path: '/vision', element: <Vision /> },
  { path: '/gallery/image', element: <AlumniGallery /> },
  { path: '/syllabus', element: <Syllabus1 /> },

  { path: '/news', element: <News /> },
  { path: '/contact', element: <Contact /> }
];

export default navBarRoutes;
