import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
function AlumniFooter() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <div
      style={{
        backgroundColor: '#2360BD',
        minHeight: '10vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        marginTop: '100px',
        flexDirection: isMobile ? 'column' : 'row',
        textAlign: isMobile ? 'center' : 'left'
      }}
    >
      <div style={{ fontSize: '14px', color: '#ffffff' }} className="font-lora">
        All Rights Reserved © By Bapuji Dental College Davangare
      </div>
      <div style={{ fontSize: '14px', color: '#ffffff' }} className="font-lora">
        {' '}
        <a
          href="https://bytecrafts.in"
          style={{ color: '#ffffff', textDecoration: 'underline' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Designed & Developed by Bytecrafts
        </a>
      </div>
    </div>
  );
}

export default AlumniFooter;
