import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material';

const Girlshostel = () => {
  const girlshostelData = [
    {
      name: 'VEDAVATHI LADIES HOSTEL',
      totalRooms: 105,
      capacity: 210,
      telephone: '08192-220242',
      recreation:
        'News Papers, Magazine, T.V., Table Tennis, Chess, Shuttle and Internet Wi-Fi Connectivity, Lift Facilities.',
      mess: 'Veg. and Non-veg.',
      warden: 'Dr. MAMATHA G.P'
    },
    {
      name: 'NETRAVATHI LADIES HOSTEL',
      totalRooms: 105,
      capacity: 210,
      telephone: '08192-220247',
      recreation:
        'News Papers, Magazine, T.V., Table Tennis, Chess, Shuttle and Internet Wi-Fi Connectivity, Lift Facilities.',
      mess: 'Veg / Non veg.',
      warden: 'Dr. MAMATHA G.P'
    },
    {
      name: 'BAPUJI INTERNATIONAL GIRLS HOSTEL',
      totalRooms: 96,
      capacity: 192,
      telephone: '08192-225846, 225847',
      recreation:
        'News Papers, Gymnasium, T.V., Table Tennis, Chess, Shuttle and Internet Wi-Fi Connectivity, Lift Facilities.',
      mess: 'Veg / Non veg.',
      warden: 'Dr. Triveni M. G.'
    }
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className="container px-4 mx-auto">
      <div className="mt-6 mb-5">
        <h1 className="mb-6 text-2xl font-semibold text-center md:text-3xl font-ebgaramond">
          Girls Hostel Information
        </h1>

        <div className="overflow-x-auto">
          <div className="min-w-[320px]">
            {girlshostelData.map((hostel, index) => (
              <div
                key={index}
                className="mb-6 overflow-hidden bg-white rounded-xl shadow-[0_10px_20px_rgba(0,0,0,0.15)] transition-all duration-300 border border-gray-100"
              >
                {/* Mobile View */}
                <div className="block md:hidden">
                  <div className="bg-[#0180A6] text-white p-5 font-ebgaramond text-xl font-bold flex items-center justify-center min-h-[100px] text-center">
                    {hostel.name}
                  </div>
                  <div className="p-4 space-y-3">
                    <p className="text-sm pl-2.5">
                      <span className="font-bold">Total Rooms:</span> {hostel.totalRooms} (Twin
                      Sharing, Attached)
                    </p>
                    <p className="text-sm pl-2.5">
                      <span className="font-bold">Maximum Capacity:</span> {hostel.capacity}
                    </p>
                    <p className="text-sm pl-2.5">
                      <span className="font-bold">Telephone No:</span> {hostel.telephone}
                    </p>
                    <p className="text-sm pl-2.5 break-words">
                      <span className="font-bold">Recreation Facility:</span> {hostel.recreation}
                    </p>
                    <p className="text-sm pl-2.5">
                      <span className="font-bold">Mess Facility:</span> {hostel.mess}
                    </p>
                    <p className="text-sm pl-2.5">
                      <span className="font-bold">Warden:</span> {hostel.warden}
                    </p>
                  </div>
                </div>

                {/* Desktop View */}
                <div className="hidden md:grid md:grid-cols-[300px_1fr] border border-gray-200">
                  <div className="bg-[#0180A6] text-white p-5 font-ebgaramond text-xl font-bold flex items-center justify-center min-h-[100px] text-center">
                    {hostel.name}
                  </div>
                  <div className="p-4 space-y-3">
                    <p className="text-base pl-2.5">
                      <span className="font-bold">Total Rooms:</span> {hostel.totalRooms} (Twin
                      Sharing, Attached)
                    </p>
                    <p className="text-base pl-2.5">
                      <span className="font-bold">Maximum Capacity:</span> {hostel.capacity}
                    </p>
                    <p className="text-base pl-2.5">
                      <span className="font-bold">Telephone No:</span> {hostel.telephone}
                    </p>
                    <p className="text-base pl-2.5 break-words">
                      <span className="font-bold">Recreation Facility:</span> {hostel.recreation}
                    </p>
                    <p className="text-base pl-2.5">
                      <span className="font-bold">Mess Facility:</span> {hostel.mess}
                    </p>
                    <p className="text-base pl-2.5">
                      <span className="font-bold">Warden:</span> {hostel.warden}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Girlshostel;
