import { is } from 'date-fns/locale';
import React, { useEffect } from 'react';
import { useState } from 'react';
import WebFont from 'webfontloader';

function AHeader({ header }) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Playfair Display:700', 'Poppins:400,600']
      }
    });
  }, []);

  return (
    <div
      className={`flex flex-col items-center justify-center text-center mb-8 ${
        isScrolled ? 'mt-[10vh]' : 'mt-[25vh]'
      }`}
    >
      <h1 className="text-4xl md:text-5xl font-bold text-gray-800 tracking-wide font-ebgaramond">
        {header}
      </h1>

      <div className="relative flex items-center justify-center w-full">
        <div className="h-[2px] bg-gray-300 w-[20%]"></div>

        <div className="relative mx-4">
          <div className="w-8 h-8 bg-gradient-to-r from-blue-500 to-teal-500 rounded-full shadow-lg animate-pulse"></div>

          <div className="absolute inset-0 w-full h-full rounded-full border-4 border-transparent border-opacity-50 animate-ping bg-gradient-to-r from-blue-300 to-teal-300"></div>
        </div>

        <div className="h-[2px] bg-gray-300 w-[20%]"></div>
      </div>
    </div>
  );
}

export default AHeader;
