import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useMediaQuery, styled } from '@mui/material';
import Boyshostel from './Boyshostel';
import Girlshostel from './Girlshostel';
import AHeader from '../components/AHeader';
import HostelGallery from './HostelGallery';

const CustomTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: '#0180A6',
    fontFamily: 'Lora, serif'
  },
  '.MuiTab-textColorInherit': {
    color: '#0180A6',
    fontFamily: 'Lora, serif'
  }
}));

const HostelPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabsData = {
    tabs: [
      {
        id: 0,
        label: 'Boys Hostel',
        content: '<p>Boys hostel Content</p>',
        heading: 'bjhb',
        boyshostel: {
          heading: 'ADMISSION PROCEDURE FOR BDS & MDS COURSES'
        }
      },
      {
        id: 1,
        label: 'Girls Hostel',
        content: '<p>Girls hostel Content</p>',
        girlshostel: {
          heading: 'admissions'
        }
      },
      {
        id: 2,
        label: 'Hostel Gallery',
        content: '',
        hostelGallery: {
          heading: 'admissions'
        }
      }
    ]
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <AHeader header={'HOSTEL'} />
      <div
        style={{
          padding: isMobile ? '5px' : '30px',
          marginLeft: isMobile ? '5px' : '100px',
          marginRight: isMobile ? '5px' : '100px',
          marginBottom: isMobile ? '30px' : '40px'
        }}
      >
        {isMobile ? (
          <div>
            <div className="w-full border-b border-gray-200">
              <div className="flex w-full">
                {tabsData.tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`flex-1 px-8 py-4 font-ebgaramond text-lg font-medium transition-all duration-200 rounded-t-lg ${
                      activeTab === tab.id
                        ? 'text-[#0180A6] border-b-2 border-[#0180A6]'
                        : 'text-gray-600 hover:text-[#0180A6] border-b-2 border-transparent'
                    }`}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            </div>
            <div>
              {tabsData.tabs.map(
                (tab) =>
                  activeTab === tab.id && (
                    <div key={tab.id} style={{ marginLeft: '10px', marginTop: '10px' }}>
                      {tab.boyshostel ? <Boyshostel boyshostelData={tab.boyshostel} /> : null}
                      {tab.girlshostel ? <Girlshostel girlshostelData={tab.girlshostel} /> : null}
                      {tab.hostelGallery ? (
                        <HostelGallery girlshostelData={tab.hostelGallery} />
                      ) : null}
                    </div>
                  )
              )}
            </div>
          </div>
        ) : (
          <>
            <Card style={{ margin: '20px', padding: '20px' }}>
              <div className="w-full border-b border-gray-200">
                <div className="flex w-full">
                  {tabsData.tabs.map((tab) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`flex-1 px-8 py-4 font-ebgaramond text-lg font-medium transition-all duration-200 rounded-t-lg ${
                        activeTab === tab.id
                          ? 'text-[#0180A6] border-b-2 border-[#0180A6]'
                          : 'text-gray-600 hover:text-[#0180A6] border-b-2 border-transparent'
                      }`}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>
              </div>
              <div>
                {tabsData.tabs.map(
                  (tab) =>
                    activeTab === tab.id && (
                      <div key={tab.id} style={{ marginLeft: '10px', marginTop: '10px' }}>
                        {tab.boyshostel ? <Boyshostel boyshostelData={tab.boyshostel} /> : null}
                        {tab.girlshostel ? <Girlshostel girlshostelData={tab.girlshostel} /> : null}
                        {tab.hostelGallery ? (
                          <HostelGallery girlshostelData={tab.hostelGallery} />
                        ) : null}
                      </div>
                    )
                )}
              </div>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

export default HostelPage;
