import React from 'react';

import DepartmentCards from './DepartmentCards';
import AboutUsHome from './AboutUsHome';
import HorizontalNews from './HorizontalNews';
import colorConfig2 from 'app/color/colorConfig2';
import PrincipalMessage from './PrincipalMessage';
import Panoroma from './Panoroma';
import ResponsiveCarousel from './ResponsiveCarousel';
import { Box, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import BDCH_GIF from 'assets/BDCH_GIF.gif';
import { useEffect } from 'react';
import { useState } from 'react';
import ImageCarousel from './Carousel';
import VicePrincipalMessage from './VicePrincipalMessage';
import VicePrincipla from './VicePrincipalHome';

const Main = () => {
  let colorConfigs = colorConfig2;

  const { bigtext } = colorConfigs;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ marginTop: '40px' }}>
      <ImageCarousel />
      <HorizontalNews colorConfig={colorConfigs} setIsLoading={setIsLoading} />
      <AboutUsHome colorConfig={colorConfigs} />
      <PrincipalMessage colorConfig={colorConfigs} />
      <VicePrincipla />
      <Panoroma />
      <div className="container px-4 mx-auto mt-5 mb-12 sm:px-6 lg:px-8">
        <div className="space-y-6">
          {/* Title */}
          <div className="px-4">
            <h2
              className="text-[#0352A1] text-2xl font-bold font-['EB_Garamond']"
              style={{ color: bigtext }}
            >
              Why choose Us
            </h2>
          </div>

          {/* Carousel */}
          <div className="flex justify-center w-full">
            <ResponsiveCarousel colorConfig={colorConfigs} />
          </div>
        </div>
      </div>
      {/* <NewWeb /> */}
      <DepartmentCards colorConfig={colorConfigs} />
    </div>
  );
};

export default Main;
