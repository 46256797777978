import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
  Typography
} from '@mui/material';
import MenuBookSharpIcon from '@mui/icons-material/MenuBookSharp';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const drawerButtonStyles = {
  color: '#1e558d',
  fontSize: '18px',
  '&:hover': {
    color: '#69b6b1',
    backgroundColor: 'transparent'
  }
};

const DrawerComponent = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const [openGallert, setOpenGallery] = useState(false);
  const [openAdministration, setOpenAdministration] = useState(false);
  const [openAcademics, setOpenAcademics] = useState(false);
  const [openDepartment, setOpenDepartment] = useState(false);

  const handleAboutClick = () => {
    setOpenAbout(!openAbout);
  };
  const handleGalleryClick = () => {
    setOpenGallery(!openGallert);
  };
  const handleAdministrationClick = () => {
    setOpenAdministration(!openAdministration);
  };
  const handleAcademicsClick = () => {
    setOpenAcademics(!openAcademics);
  };
  const handleDepartmentClick = () => {
    setOpenDepartment(!openDepartment);
  };

  return (
    <>
      <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/" sx={drawerButtonStyles}>
                  Home
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>

          {/* About */}
          <ListItem onClick={handleAboutClick}>
            <ListItemText>
              <Typography sx={drawerButtonStyles}>About Us</Typography>
            </ListItemText>
            {openAbout ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openAbout} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAbout(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button component={Link} to="/aboutUs" sx={drawerButtonStyles}>
                      About Us
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAbout(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button component={Link} sx={drawerButtonStyles}>
                      Prospectus
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* Administration */}
          <ListItem onClick={handleAdministrationClick}>
            <ListItemText>
              <Typography sx={drawerButtonStyles}>Administration</Typography>
            </ListItemText>
            {openAdministration ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openAdministration} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAdministration(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to="/administration/management"
                      sx={drawerButtonStyles}
                    >
                      Management
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAdministration(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button component={Link} to="/administration/principal" sx={drawerButtonStyles}>
                      Principal
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAdministration(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to="/administration/viceprincipal"
                      sx={drawerButtonStyles}
                    >
                      Vice Principal
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAdministration(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to="/administration/affiliation"
                      sx={drawerButtonStyles}
                    >
                      Affiliation
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAdministration(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to="/administration/committees"
                      sx={drawerButtonStyles}
                    >
                      Committees
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAdministration(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button component={Link} to="/administration/rti" sx={drawerButtonStyles}>
                      RTI
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* Academic affairs */}
          <ListItem onClick={handleAcademicsClick}>
            <ListItemText>
              <Typography sx={drawerButtonStyles}>Academic Affairs</Typography>
            </ListItemText>
            {openAcademics ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openAcademics} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAcademics(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to="/academic-affairs/academic-details"
                      sx={drawerButtonStyles}
                    >
                      Academic Details
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAcademics(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to="/academic-affairs/exam-schedule"
                      sx={drawerButtonStyles}
                    >
                      Exam Schedule
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAcademics(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to="/academic-affairs/admission"
                      sx={drawerButtonStyles}
                    >
                      Admission
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAcademics(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button component={Link} to="/academic-affairs/hostel" sx={drawerButtonStyles}>
                      Hostel Facilities
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAcademics(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button component={Link} to="/academic-affairs/library" sx={drawerButtonStyles}>
                      Library
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAcademics(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to="/academic-affairs/students"
                      sx={drawerButtonStyles}
                    >
                      Students
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              F
            </List>
          </Collapse>

          {/* Departments */}
          <ListItem onClick={handleDepartmentClick}>
            <ListItemText>
              <Typography sx={drawerButtonStyles}>Department</Typography>
            </ListItemText>
            {openDepartment ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDepartment} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'ORAL_MEDICINE'}`}
                      sx={drawerButtonStyles}
                    >
                      Oral Medicine & Radiology
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'ORAL_SURGERY'}`}
                      sx={drawerButtonStyles}
                    >
                      Oral and Maxillofacial
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'PEDODONTICS'}`}
                      sx={drawerButtonStyles}
                    >
                      Pedodontics & Preventive Dentistry
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'ORTHODONTICS'}`}
                      sx={drawerButtonStyles}
                    >
                      Orthodontics & Dentofacial Orthopedics
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'PERIODONTOLOGY'}`}
                      sx={drawerButtonStyles}
                    >
                      Periodontology
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'CONSERVATIVE'}`}
                      sx={drawerButtonStyles}
                    >
                      Conservative Dentistry & Endodontics
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'PROSTHODONTICS'}`}
                      sx={drawerButtonStyles}
                    >
                      Prosthodontics and Crown & Bridge
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'PUBLIC_HEALTH'}`}
                      sx={drawerButtonStyles}
                    >
                      Public Health Dentistry
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'PATHOLOGY'}`}
                      sx={drawerButtonStyles}
                    >
                      Oral Pathology & Microbiology
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'IMPLANTOLOGY'}`}
                      sx={drawerButtonStyles}
                    >
                      Implantology
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenDepartment(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button
                      component={Link}
                      to={`/departments/overview/${'BIOSTATISTICS'}`}
                      sx={drawerButtonStyles}
                    >
                      Biostatistics
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/news" sx={drawerButtonStyles}>
                  News And Events
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem onClick={handleGalleryClick}>
            <ListItemText>
              <Typography sx={drawerButtonStyles}>Gallery</Typography>
            </ListItemText>
            {openGallert ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openGallert} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenGallery(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button component={Link} to="/gallery/image" sx={drawerButtonStyles}>
                      Image Gallery
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenAbout(false);
                }}
              >
                <ListItemText>
                  <Typography>
                    <Button component={Link} sx={drawerButtonStyles}>
                      Video Gallery
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} sx={drawerButtonStyles}>
                  NIRF
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/alumni" sx={drawerButtonStyles}>
                  Alumni
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/iqac" sx={drawerButtonStyles}>
                  IQAC
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem component={Link} onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/contact" sx={drawerButtonStyles}>
                  Contact Us
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/syllabus" sx={drawerButtonStyles}>
                  Syllabus
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuBookSharpIcon style={{ fontSize: '2em', color: 'white' }} />
      </IconButton>
    </>
  );
};

export default DrawerComponent;
