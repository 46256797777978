import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  useTheme,
  useMediaQuery,
  Divider,
  Button
} from '@mui/material';
import DepNameBar from './DepNameBar';
import departmentData from './departmentData.json';
import DepButtons from './DepButtons';
import { useParams } from 'react-router-dom';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import { getFacultyDetailsByDept } from '../BackOfiice/components/UtilityFunctions';
import { useState } from 'react';
import { useEffect } from 'react';
import img1 from './image.png';
import BDCH_GIF from 'assets/BDCH_GIF.gif';
import { CleaningServices } from '@mui/icons-material';
import AnimatedDivider from './DeptHeading';

const Faculty = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // console.log('overview', id);
  const [facultyArray, setFacultyArray] = useState([]);
  const [facultydata, setFacultyData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getFacultyDetailsByDept(setFacultyData, id, setLoading);
  }, [id]);

  useEffect(() => {
    if (facultydata.length >= 1) {
      setFacultyArray(sortedArray);
    }
    console.log(facultydata);
  }, [facultydata]);

  const sortedArray = facultydata.sort((a, b) => {
    if (a.prioritywise !== undefined && b.prioritywise !== undefined) {
      return a.prioritywise - b.prioritywise;
    } else if (a.prioritywise !== undefined) {
      return -1;
    } else if (b.prioritywise !== undefined) {
      return 1;
    } else {
      return 0;
    }
  });

  const department = departmentData[id];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const openPDF = (pdfUrl, title) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' +
        pdfUrl +
        '" title="' +
        title +
        '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <img src={BDCH_GIF} alt="Loading..." />
      </div>
    );
  }

  return (
    <div className={` mx-auto ${isMobile ? 'mt-[8vh]' : 'mt-[28vh]'}`}>
      {isMobile ? <DepNameBar id={id} department={department} /> : null}
      {!isMobile && <AnimatedDivider message={`${department?.name} Faculty's`} />}

      <div className="flex flex-wrap justify-center pt-10">
        {!isMobile && (
          <div className="w-full pr-5 md:w-1/6">
            <DepButtons id={id} />
          </div>
        )}

        <div className="w-full md:w-2/3">
          {facultyArray?.length > 0 &&
            facultyArray?.map((faculty, index) => (
              <div key={index} className="mb-8 overflow-hidden bg-white shadow-lg rounded-xl">
                <div className="p-6">
                  <div className="flex flex-col md:flex-row">
                    {/* Faculty Image & Name */}
                    <div className="flex flex-col items-center w-full pr-0 md:pr-8 font-ebgaramond md:w-1/3">
                      <div className="w-full max-w-[280px] mx-auto relative rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 bg-gradient-to-r from-[#0352A1] via-[#0180A6] to-[#00B4AC]">
                        <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-[#0352A1] via-[#0180A6] to-[#00B4AC] blur opacity-50"></div>
                        <div className="relative bg-white rounded-xl">
                          <img
                            src={faculty?.facProfile || img1}
                            alt={faculty?.name?.firstName}
                            className="object-contain w-full h-full rounded-xl"
                          />
                        </div>
                      </div>
                      <div className="text-center bg-[#00B4AC]/10 hover:bg-[#00B4AC]/20 rounded-lg shadow-sm hover:shadow-md transition-all duration-300 w-full max-w-[280px] mx-auto">
                        <p className="text-[#0352A1] text-lg font-semibold">
                          Dr. {faculty?.name?.firstName} {faculty?.name?.lastName}
                        </p>
                      </div>
                    </div>

                    {/* Faculty Details */}
                    <div className="w-full font-lora md:w-2/3">
                      <div className="p-6 bg-white rounded-lg shadow-lg">
                        <table className="w-full">
                          <tbody>
                            <tr className="border-b">
                              <td className="w-1/3 py-3 font-semibold text-[#0352A1]">Name</td>
                              <td className="py-3 text-[#2C3E50]">
                                Dr. {faculty?.name?.firstName} {faculty?.name?.lastName}
                              </td>
                            </tr>
                            <tr className="border-b">
                              <td className="py-3 font-semibold text-[#0352A1]">Designation</td>
                              <td className="py-3 text-[#2C3E50]">{faculty?.designation}</td>
                            </tr>
                            <tr className="border-b">
                              <td className="py-3 font-semibold text-[#0352A1]">Qualification</td>
                              <td className="py-3 text-[#2C3E50]">{faculty?.qualification}</td>
                            </tr>
                            {faculty?.areaOfInterest && (
                              <tr className="border-b">
                                <td className="py-3 font-semibold text-[#0352A1]">
                                  Area of Interest
                                </td>
                                <td className="py-3 text-justify text-[#2C3E50]">
                                  {faculty?.areaOfInterest}
                                </td>
                              </tr>
                            )}
                            {faculty?.achievement?.length > 0 && (
                              <tr className="border-b">
                                <td className="py-3 font-semibold text-[#0352A1] align-top">
                                  Achievement
                                </td>
                                <td className="py-3">
                                  {faculty?.achievement.map((item, idx) => (
                                    <div key={idx} className="flex mb-3 text-[#2C3E50]">
                                      <span className="mr-3 font-medium">{idx + 1}.</span>
                                      <span className="text-justify">{item}</span>
                                    </div>
                                  ))}
                                </td>
                              </tr>
                            )}
                            {faculty?.pdfFiles && (
                              <tr>
                                <td></td>
                                <td className="py-3">
                                  <button
                                    onClick={() =>
                                      openPDF(faculty?.pdfFiles, faculty?.name?.firstName)
                                    }
                                    className="px-4 py-2 bg-[#00B4AC]/10 text-[#0352A1] rounded-lg shadow-sm"
                                  >
                                    View Details
                                  </button>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Faculty;
