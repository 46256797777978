import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAllDirectory } from 'api-ws/aluminiDirectory-ws';

const StudentDirectory = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [activeCell, setActiveCell] = useState(null);
  const [bdsStudentList, setBdsStudentList] = useState();
  const [mdsStudentList, setMdsStudentList] = useState();

  useEffect(() => {
    fetchDirectoryDetails();
  }, []);

  const fetchDirectoryDetails = async () => {
    try {
      const res = await getAllDirectory();
      setBdsStudentList(res?.data?.data[0]?.bdsStudentlist);
      setMdsStudentList(res?.data?.data[0]?.mdsStudentList);
    } catch (err) {
      console.log(err);
    }
  };

  const openPDF = (pdfUrl) => {
    const win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ marginBottom: isMobile ? '150px' : '10px' }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{
          marginTop: '50px',
          marginLeft: isMobile ? '1px' : '10px',
          marginRight: '20px',
          textAlign: 'center',
          marginBottom: '30px'
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 'bold',
              fontSize: isMobile ? '24px' : '34px',
              fontFamily: "'EB Garamond', serif"
            }}
          >
            Student Directory
          </Typography>
          <Divider
            sx={{
              marginTop: '35px',
              marginBottom: '30px',
              marginLeft: { md: '50px' },
              width: { xs: '90%', md: '90%' }
            }}
          />
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
          <Grid item xs={12}>
            <Grid sx={{ marginTop: '20px', marginBottom: '10px' }}>
              <TableContainer
                component={Paper}
                sx={{
                  marginTop: '25px',
                  width: isMobile ? '80%' : '60%',
                  margin: 'auto',
                  textAlign: 'center'
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: '#2360BD',
                          fontSize: isMobile ? '20px' : '28px',
                          fontWeight: 'bold',
                          color: '#ffffff'
                        }}
                        style={{ fontFamily: "'EB Garamond', serif" }}
                        align="center"
                      >
                        BDS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        onClick={() => openPDF(bdsStudentList)}
                        style={{
                          cursor: 'pointer',
                          color: activeCell === 0 ? 'blue' : 'inherit',
                          fontSize: isMobile ? '14px' : '16px',
                          fontFamily: 'Lora, serif'
                        }}
                      >
                        BDS Students List
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid sx={{ marginTop: '15px', marginBottom: '10px' }}>
              <TableContainer
                component={Paper}
                sx={{
                  marginTop: '20px',
                  width: isMobile ? '80%' : '60%',
                  margin: 'auto',
                  textAlign: 'center'
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: '#2360BD',
                          fontSize: isMobile ? '20px' : '28px',
                          fontWeight: 'bold',
                          color: '#ffffff'
                        }}
                        align="center"
                        style={{ fontFamily: "'EB Garamond', serif" }}
                      >
                        MDS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        onClick={() => openPDF(mdsStudentList)}
                        style={{
                          cursor: 'pointer',
                          color: activeCell === 0 ? 'blue' : 'inherit',
                          fontSize: isMobile ? '14px' : '16px',
                          fontFamily: 'Lora, serif'
                        }}
                      >
                        MDS Students List
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default StudentDirectory;
