import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Hourglass } from 'react-loader-spinner';
import ImageViewer from 'react-simple-image-viewer';
import DepButtons from './DepButtons';
import DepNameBar from './DepNameBar';
import departmentData from './departmentData.json';
import AnimatedDivider from './DeptHeading';

function DepartmentGallery() {
  const { id } = useParams();
  const department = departmentData[id];
  const galleryData = department?.gallery;
  const srcArray = galleryData?.map((item) => item.src);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  if (!department) {
    return <div className="mt-[40vh]">Department not found</div>;
  }

  return (
    <div className={`${isMobile ? 'mt-[8vh]' : 'mt-[28vh]'}`}>
      {isMobile ? <DepNameBar id={id} department={department} /> : null}

      {!isMobile && <AnimatedDivider message={`${department?.name} Gallery`} />}

      <div className="flex flex-wrap justify-center pt-10">
        {!isMobile && (
          <div className="w-full pr-5 md:w-1/6">
            <DepButtons id={id} />
          </div>
        )}

        <div className="w-full md:w-2/3">
          {loading && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-white/80">
              <Hourglass visible={true} height="80" width="80" colors={['#97905E', '#000000']} />
            </div>
          )}
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            {galleryData?.map((image, index) => (
              <div key={index} className="flex justify-center">
                <div className="w-[320px] overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 bg-[#00B4AC]/10 hover:bg-[#00B4AC]/20">
                  <div className="relative h-[200px] w-full">
                    <img
                      src={image.src}
                      alt={image.alt}
                      onClick={() => openImageViewer(index)}
                      className="absolute inset-0 object-cover w-full h-full cursor-pointer"
                    />
                  </div>
                  <div className="min-h-[60px] p-4 font-ebgaramond font-medium text-center flex items-center justify-center">
                    <p className="text-[#0352A1] font-medium break-words">{image.alt}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {isViewerOpen && (
            <div className="fixed inset-0 z-50">
              <ImageViewer
                src={srcArray}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DepartmentGallery;
