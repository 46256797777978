import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import pdf11 from './201415-mds.pdf';
import pdf12 from './201516-mds.pdf';
import pdf13 from './201617-mds.pdf';
import pdf14 from './201718-mds.pdf';
import pdf15 from './201819-mds.pdf';
import pdf16 from './201920-mds.pdf';
import pdf17 from './202021-mds.pdf';
import pdf18 from './202122-mds.pdf';
import pdf19 from './202223-mds.pdf';
import pdf1 from './201314bdsbatch.pdf';
import pdf3 from './201516bdsbatchstudents.pdf';
import pdf4 from './201617bdsbatchstudents.pdf';
import pdf5 from './201718bdsbatchstudents.pdf';
import pdf6 from './201819bdsbatchstudents.pdf';
import pdf7 from './201920bdsbatchstudents.pdf';
import pdf8 from './202021bdsbatchstudents.pdf';
import pdf9 from './202122bdsbatchstudents.pdf';
import pdf10 from './202223bdsbatchstudents.pdf';
import pdf20 from './2023-24 BDS Batch Students.pdf';
import pdf21 from './2023-24 MDS Batch Students.pdf';
import pdf22 from './2024-25 MDS Batch Students.pdf';
import pdf23 from './2024-25 BDS Batch Students.pdf';
import AHeader from '../components/AHeader';
import colorConfig2 from 'app/color/colorConfig2';
const { backgroundColor, smalltext, bigtext } = colorConfig2;
const Students = () => {
  const bdsStudentList = [
    {
      Subject: '2013-14 BDS Batch Students',
      pdfUrl: pdf1
    },
    {
      Subject: '2014-15 BDS Batch Students',
      pdfUrl: null // Placeholder for missing PDF
    },
    {
      Subject: '2015-16 BDS Batch Students',
      pdfUrl: pdf3
    },
    {
      Subject: '2016-17 BDS Batch Students',
      pdfUrl: pdf4
    },
    {
      Subject: '2017-18 BDS Batch Students',
      pdfUrl: pdf5
    },
    {
      Subject: '2018-19 BDS Batch Students',
      pdfUrl: pdf6
    },
    {
      Subject: '2019-20 BDS Batch Students',
      pdfUrl: pdf7
    },
    {
      Subject: '2020-21 BDS Batch Students',
      pdfUrl: pdf8
    },
    {
      Subject: '2021-22 BDS Batch Students',
      pdfUrl: pdf9
    },
    {
      Subject: '2022-23 BDS Batch Students',
      pdfUrl: pdf10
    },
    {
      Subject: '2023-24 BDS Batch Students',
      pdfUrl: pdf20
    },
    {
      Subject: '2024-25 BDS Batch Students',
      pdfUrl: pdf23
    }
  ];

  const mdsStudentList = [
    {
      Subject: '2013-14 MDS Batch Students',
      pdfUrl: null
    },
    {
      Subject: '2014-15 MDS Batch Students',
      pdfUrl: pdf11
    },
    {
      Subject: '2015-16 MDS Batch Students',
      pdfUrl: pdf12
    },
    {
      Subject: '2016-17 MDS Batch Students',
      pdfUrl: pdf13
    },
    {
      Subject: '2017-18 MDS Batch Students',
      pdfUrl: pdf14
    },
    {
      Subject: '2018-19 MDS Batch Students',
      pdfUrl: pdf15
    },
    {
      Subject: '2019-20 MDS Batch Students',
      pdfUrl: pdf16
    },
    {
      Subject: '2020-21 MDS Batch Students',
      pdfUrl: pdf17
    },
    {
      Subject: '2021-22 MDS Batch Students',
      pdfUrl: pdf18
    },
    {
      Subject: '2022-23 MDS Batch Students',
      pdfUrl: pdf19
    },
    {
      Subject: '2023-24 MDS Batch Students',
      pdfUrl: pdf21
    },
    {
      Subject: '2024-25 MDS Batch Students',
      pdfUrl: pdf22
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openPDF = (pdfUrl) => {
    if (pdfUrl) {
      const win = window.open();
      win.document.write(
        `<iframe src="${pdfUrl}" style="width:100%; height:100%; border:none;"></iframe>`
      );
    }
  };

  return (
    <>
      <AHeader header={'STUDENTS LIST'} />
      <div className="container px-4 py-8 mx-auto">
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="w-full lg:w-3/4">
            <div className="overflow-hidden transition-shadow duration-300 bg-white border border-gray-200 shadow-lg rounded-xl hover:shadow-xl">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gradient-to-r from-[#f5f5f5] via-[#f5f5f5] to-[#f5f5f5]">
                    {' '}
                    <tr>
                      <th className="px-6 py-4 text-2xl text-[#0352A1] font-garamond">BDS</th>
                      <th className="px-6 py-4 text-2xl text-[#0352A1] font-garamond">MDS</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {bdsStudentList.map((bdsStudent, index) => {
                      const mdsStudent = mdsStudentList[index] || {};
                      return (
                        <tr key={index}>
                          <td
                            className="px-6 py-4 text-center cursor-pointer font-lora text-gray-700 
                              hover:text-[#0352A1] hover:bg-blue-50 transition-all duration-200"
                            onClick={() => openPDF(bdsStudent.pdfUrl)}
                          >
                            {bdsStudent.Subject || 'N/A'}
                          </td>
                          <td
                            className="px-6 py-4 text-center cursor-pointer font-lora text-gray-700 
                              hover:text-[#0352A1] hover:bg-blue-50 transition-all duration-200"
                            onClick={() => openPDF(mdsStudent.pdfUrl)}
                          >
                            {mdsStudent.Subject || 'N/A'}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <VerticalNewsBanner />
          </div>
        </div>
      </div>
    </>
  );
};

export default Students;
