import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import AHeader from '../components/AHeader';
import certificate from './certificate.jpg';
import govApprovalPdf from './GovernmentApp.pdf';
import rguhsAffiliationPdf from './Affiliation23-24.pdf';

const Affiliation = () => {
  const cardContent = [
    {
      title: 'NAAC Accreditation Certificate',
      description: '',
      imageUrl: certificate,
      isImage: true
    },
    {
      title: 'Government Approval',
      description: 'View Government Approval',
      pdfUrl: govApprovalPdf,
      isImage: false
    },
    {
      title: 'Affiliation for BDS, MDS and Phd Courses',
      description: 'View Affiliation for BDS, MDS and Phd Courses',
      pdfUrl: rguhsAffiliationPdf,
      isImage: false
    }
  ];

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openImage = (imageUrl) => {
    const win = window.open();
    const img = win.document.createElement('img');
    img.src = imageUrl;
    img.style.width = '100%';
    img.style.height = '100%';
    img.style.objectFit = 'contain';
    win.document.body.appendChild(img);
  };

  const openPdf = (pdfUrl) => {
    const win = window.open();
    win.document.write(
      `<iframe src="${pdfUrl}" style="width:100%; height:100%; border:none;"></iframe>`
    );
  };

  return (
    <div className="min-h-screen">
      <div className="container px-4 py-16 mx-auto">
        <div className="text-center">
          <AHeader header={'AFFILIATION'} />
          <div
            className="max-w-3xl mx-auto p-8 bg-gradient-to-r from-white to-blue-50 rounded-xl 
              shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] 
              border border-blue-100 hover:scale-[1.02] transition-all duration-300 ease-in-out"
          >
            <div className="flex flex-col items-center space-y-4">
              {/* <img
                src="/assets/images/rguhs.png"
                alt="RGUHS Logo"
                className="object-contain w-24 h-24 mb-4"
              /> */}
              <p
                className="text-xl md:text-2xl font-garamond text-gray-800 leading-relaxed
                text-center tracking-wide hover:text-[#0352A1] transition-colors duration-300
                border-b-2 border-transparent hover:border-[#0352A1] pb-2"
              >
                Affiliated to Rajiv Gandhi University of Health Sciences,
                <span className="block mt-1 font-semibold">Karnataka, Bangalore</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affiliation;
