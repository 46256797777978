import React, { useState, useEffect } from 'react';
import { styled, Grid, Card, CardContent, Typography, Button, Avatar } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Choosefile from './Choosefile';
import { addMemberShip } from 'api-ws/aluminiMemberShip-ws';
import ProfilePicUploader from '../BackOfiice/components/ProfilePicUploader';
import { SimpleCard } from 'app/components';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px'
}));

const MembershipForm = () => {
  const [alumniMembershipProfile, setAlumniMembershipProfile] = useState();
  const [name, setName] = useState({ firstName: '', lastName: '' });
  const [professionalInformation, setProfessionalInformation] = useState({
    occupation: '',
    universityRegNo: '',
    bdsJoiningDate: '',
    mdsJoiningDate: ''
  });
  const [personalInformation, setPersonalInformation] = useState({
    dateOfBirth: '',
    email: '',
    phoneNumber: '',
    spouseName: '',
    children: ''
  });
  const [address, setAddress] = useState({
    streetName: '',
    city: '',
    state: '',
    country: '',
    pincode: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const request = {
      alumniMembershipProfile: alumniMembershipProfile,
      name: { firstName: name?.firstName, lastName: name?.lastName },
      professionalInformation: {
        occupation: professionalInformation?.occupation,
        universityRegNo: professionalInformation?.universityRegNo,
        bdsJoiningDate: professionalInformation?.bdsJoiningDate,
        mdsJoiningDate: professionalInformation?.mdsJoiningDate
      },
      personalInformation: {
        dateOfBirth: personalInformation?.dateOfBirth,
        email: personalInformation?.email,
        phoneNumber: personalInformation?.phoneNumber,
        spousesName: personalInformation?.spouseName,
        children: personalInformation?.children
      },
      address: {
        streetName: address?.streetName,
        city: address?.city,
        state: address?.state,
        country: address?.country,
        pincode: address?.pincode
      }
    };
    addMemberShip(request)
      .then((res) => {
        alert('Operation successful!');
      })
      .catch((err) => {
        alert('Error in adding membership');
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card
      style={{
        width: '80%',
        margin: 'auto',
        marginTop: '40px',
        padding: '20px'
      }}
    >
      <div className="container">
        <Typography
          variant="h3"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 8,
            fontFamily: "'EB Garamond', serif"
          }}
        >
          Application Form for Life Membership
        </Typography>

        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <SimpleCard>
                <Avatar
                  alt="Clinic"
                  src={alumniMembershipProfile?.base64}
                  style={{ width: '150px', height: '150px', margin: 'auto' }}
                />
                <Grid
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20px'
                  }}
                >
                  <ProfilePicUploader setFacProfile={setAlumniMembershipProfile} />
                </Grid>
              </SimpleCard>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{ marginBottom: '4px', fontSize: 20, fontFamily: 'Lora, serif' }}>
                Personal Information:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    value={name.firstName}
                    onChange={(e) => setName({ ...name, firstName: e.target.value })}
                    errorMessages={['First name is required']}
                    validators={['required']}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={name?.lastName}
                    onChange={(e) => setName({ ...name, lastName: e.target.value })}
                    errorMessages={['Last name is required']}
                    validators={['required']}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="tel"
                    label="WhatsApp Number"
                    name="phoneNumber"
                    value={personalInformation?.phoneNumber}
                    onChange={(e) =>
                      setPersonalInformation({
                        ...personalInformation,
                        phoneNumber: e.target.value
                      })
                    }
                    validators={['required', 'isNumber', 'matchRegexp:^\\d{10}$', 'minNumber:1']}
                    errorMessages={[
                      'Phone number is required',
                      'Must be a number',
                      'Phone number must be 10 digits',
                      'Must be a positive number'
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: '15px', marginLeft: 0.5 }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Date of Birth"
                  name="dateOfBirth"
                  value={personalInformation?.dateOfBirth}
                  onChange={(e) =>
                    setPersonalInformation({
                      ...personalInformation,
                      dateOfBirth: e.target.value
                    })
                  }
                  validators={['required']}
                  errorMessages={['Date of Birth is required']}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={personalInformation?.email}
                  onChange={(e) =>
                    setPersonalInformation({
                      ...personalInformation,
                      email: e.target.value
                    })
                  }
                  validators={['required', 'isEmail']}
                  errorMessages={['Email is required', 'Must be a valid email address']}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Spouse's Name"
                  name="spousesName"
                  value={personalInformation?.spouseName}
                  onChange={(e) =>
                    setPersonalInformation({
                      ...personalInformation,
                      spouseName: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Children"
                  name="children"
                  value={personalInformation?.children}
                  onChange={(e) =>
                    setPersonalInformation({
                      ...personalInformation,
                      children: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ marginBottom: '4px', fontSize: 20, fontFamily: 'Lora, serif' }}>
                Address:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Street Name"
                    name="streetName"
                    value={address?.streetName}
                    onChange={(e) =>
                      setAddress({
                        ...address,
                        streetName: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    value={address?.city}
                    onChange={(e) =>
                      setAddress({
                        ...address,
                        city: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="State"
                    name="state"
                    value={address?.state}
                    onChange={(e) =>
                      setAddress({
                        ...address,
                        state: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    value={address?.country}
                    onChange={(e) =>
                      setAddress({
                        ...address,
                        country: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Pincode"
                    name="pinCodeNo"
                    value={address?.pincode}
                    onChange={(e) =>
                      setAddress({
                        ...address,
                        pincode: e.target.value
                      })
                    }
                    errorMessages={['Pincode is required']}
                    validators={['required']}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ marginBottom: '4px', fontSize: 20, fontFamily: 'Lora, serif' }}>
                Professional Information:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Occupation"
                    name="occupation"
                    value={professionalInformation?.occupation}
                    onChange={(e) =>
                      setProfessionalInformation({
                        ...professionalInformation,
                        occupation: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="University Registration No"
                    name="universityRegNo"
                    value={professionalInformation?.universityRegNo}
                    onChange={(e) =>
                      setProfessionalInformation({
                        ...professionalInformation,
                        universityRegNo: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="date"
                    name="bdsJoiningDate"
                    label="bdsJoiningDate"
                    value={professionalInformation?.bdsJoiningDate}
                    onChange={(e) =>
                      setProfessionalInformation({
                        ...professionalInformation,
                        bdsJoiningDate: e.target.value
                      })
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="date"
                    name="mdsJoiningDate"
                    label="mdsJoiningDate"
                    value={professionalInformation?.mdsJoiningDate}
                    onChange={(e) =>
                      setProfessionalInformation({
                        ...professionalInformation,
                        mdsJoiningDate: e.target.value
                      })
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
              padding: '30px',
              height: '100px'
            }}
          >
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: '#2360BD', fontWeight: 'bold', fontFamily: 'Lora, serif' }}
            >
              Send Message
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </Card>
  );
};

export default MembershipForm;
