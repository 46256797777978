import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import colorConfig2 from 'app/color/colorConfig2';

function DepButtons({ id }) {
  const { navbarColor } = colorConfig2;
  const location = useLocation();
  const currentRoute = location.pathname;

  const isButtonSelected = (buttonRoute) => currentRoute === `/departments${buttonRoute}/${id}`;

  const buttonClasses = (buttonRoute) =>
    isButtonSelected(buttonRoute)
      ? `border-2 border-[${navbarColor}] font-bold text-[${navbarColor}]`
      : `text-gray-700 hover:text-[${navbarColor}]`;

  const buttonRoutes = [
    { route: '/overview', label: 'Overview' },
    { route: '/hod', label: 'HOD' },
    { route: '/faculty', label: 'Faculty' },
    { route: '/activities', label: 'Activities' },
    { route: '/awards', label: 'Awards' },
    { route: '/publications', label: 'Publications' },
    { route: '/presentation', label: 'Presentation' },
    { route: '/dissertation', label: 'Dessertation' },
    // { route: '/syllabus', label: 'Syllabus' },
    { route: '/gallery', label: 'Gallery' }
  ];

  return (
    <div className="sticky top-20 max-w-[280px]">
      {' '}
      {/* Added max-width */}
      <div className="flex flex-col h-auto transition-shadow duration-300 bg-white rounded-lg shadow-lg hover:shadow-xl">
        {/* Header */}
        <div className="flex items-center justify-center h-12 font-semibold font-ebgaramond text-white rounded-t-lg bg-[#0352A1]">
          Department Details
        </div>

        {/* Buttons */}
        <div className="flex flex-col font-lora items-stretch justify-start p-4 space-y-2">
          {buttonRoutes.map(({ route, label }) => (
            <Link
              key={route}
              to={`/departments${route}/${id}`}
              className={`relative py-3 px-4 rounded-md transition-all duration-300 hover:bg-[#00B4AC]/5 group
                ${isButtonSelected(route) ? 'bg-[#00B4AC]/10' : ''}`}
            >
              <span
                className={`block text-center text-base font-medium transition-colors duration-300
                  ${
                    isButtonSelected(route)
                      ? 'text-[#00B4AC]'
                      : 'text-gray-700 group-hover:text-[#00B4AC]'
                  }`}
              >
                {label}
              </span>
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-[#00B4AC] transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DepButtons;
