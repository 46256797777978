import React, { useState, useEffect } from 'react';

const CarouselPage = ({ carouselData }) => {
  const [current, setCurrent] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);

  console.log('dnjknfjd', carouselData);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isTransitioning) {
        handleSlideChange((prev) => (prev === carouselData.length - 1 ? 0 : prev + 1));
      }
    }, 3000);
    return () => clearInterval(timer);
  }, [carouselData.length, isTransitioning]);

  const handleSlideChange = (newIndex) => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setFadeIn(false);
    setTimeout(() => {
      setCurrent(newIndex);
      setFadeIn(true);
      setTimeout(() => {
        setIsTransitioning(false);
      }, 500);
    }, 500);
  };

  return (
    <div className="relative w-full h-[550px] overflow-hidden group">
      {carouselData.map((item, index) => (
        <div
          key={index}
          className={`absolute w-full h-full transition-opacity duration-500 ease-in-out ${
            index === current ? (fadeIn ? 'opacity-100' : 'opacity-0') : 'opacity-0'
          }`}
        >
          <img src={item.imageUrl} alt={item.heading} className="object-cover w-full h-full" />
          <div className="absolute inset-0 bg-black/30">
            <h1 className="absolute text-4xl font-bold text-center text-white transform -translate-x-1/2 top-5 left-1/2 md:text-5xl drop-shadow-lg">
              {item.heading}
            </h1>
          </div>
        </div>
      ))}

      <button
        onClick={() => handleSlideChange(current === 0 ? carouselData.length - 1 : current - 1)}
        disabled={isTransitioning}
        className={`absolute hidden p-4 text-[#fff] transition-all -translate-y-1/2 rounded-full group-hover:block top-1/2 left-5 bg-black/50 ${
          isTransitioning ? 'cursor-not-allowed opacity-50' : ''
        }`}
      >
        ←
      </button>
      <button
        onClick={() => handleSlideChange(current === carouselData.length - 1 ? 0 : current + 1)}
        disabled={isTransitioning}
        className={`absolute hidden p-4 text-[#fff] transition-all -translate-y-1/2 rounded-full group-hover:block top-1/2 right-5 bg-black/50 ${
          isTransitioning ? 'cursor-not-allowed opacity-50' : ''
        }`}
      >
        →
      </button>

      <div className="absolute left-0 right-0 bottom-4">
        <div className="flex justify-center gap-2">
          {carouselData.map((_, index) => (
            <button
              key={index}
              onClick={() => !isTransitioning && handleSlideChange(index)}
              disabled={isTransitioning}
              className={`w-3 h-3 rounded-full transition-all ${
                index === current ? 'bg-white scale-125' : 'bg-white/50 hover:bg-white/75'
              } ${isTransitioning ? 'cursor-not-allowed' : ''}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselPage;
