import React from 'react';
import {
  useMediaQuery,
  Grid,
  Typography,
  useTheme,
  Divider,
  styled,
  Card,
  Tabs,
  Tab,
  Button
} from '@mui/material';
import DepButtons from './DepButtons';
import DepNameBar from './DepNameBar';
import departmentData from './departmentData.json';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import colorConfig2 from 'app/color/colorConfig2';
import AnimatedDivider from './DeptHeading';

const { navbarColor, bigtext } = colorConfig2;

const CustomTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: navbarColor
  },
  '.MuiTab-textColorInherit': {
    color: navbarColor
  }
}));

function DepartmentSyllabus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [activeTab, setActiveTab] = useState(0);
  const { id } = useParams();
  //   console.log('overview', id);
  const department = departmentData[id].syllabus;
  const department_bds = departmentData[id].syllabus.bds;
  const department_mds = departmentData[id].syllabus.mds;
  console.log('d', department_bds);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const openPDF = (pdfUrl) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  const tabsData = {
    tabs: [
      {
        id: 0,
        label: 'BDS Syllabus',
        content: '<p>BDS Content</p>',
        bdssyllabus: {
          heading: 'BDS syllabus'
        }
      },
      {
        id: 1,
        label: 'MDS Syllabus',
        content: '<p>MDS Content</p>',
        mdssyllabus: {
          heading: 'MDS syllabus'
        }
      }
    ]
  };

  if (!department) {
    return <div style={{ marginTop: '40vh' }}>Department not found</div>;
  }
  return (
    <div className={`container mx-auto ${isMobile ? 'mt-[8vh]' : 'mt-[28vh]'}`}>
      {isMobile ? <DepNameBar id={id} department={department} /> : null}
      {!isMobile && <AnimatedDivider message="SYLLABUS" />}

      <div className="flex flex-wrap justify-center pt-10">
        {!isMobile && (
          <div className="w-full pr-5 md:w-1/6">
            <DepButtons id={id} />
          </div>
        )}

        <div className="w-full md:w-2/3">
          <div className="p-4 bg-white shadow-lg rounded-xl">
            {/* Tabs */}
            <div className="flex ml-4">
              {tabsData.tabs.map((tab, index) => (
                <button
                  key={tab.id}
                  onClick={() => handleTabChange(null, tab.id)}
                  className={`relative  px-8 py-4 font-semibold text-lg transition-all duration-300
                    ${index === 0 ? 'rounded-tl-xl' : ''} 
                    ${
                      index === tabsData.tabs.length - 1
                        ? 'rounded-tr-xl'
                        : 'border-r border-gray-200'
                    }
                    hover:scale-105 hover:shadow-lg outline-none border-none
                    ${
                      activeTab === tab.id
                        ? 'text-[#00B4AC] after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#00B4AC] bg-gradient-to-r from-[#00B4AC]/5 to-transparent shadow-md animate-pulse'
                        : 'text-gray-500 hover:text-[#00B4AC] hover:bg-[#00B4AC]/5'
                    }`}
                >
                  {tab.label}
                </button>
              ))}
            </div>

            {/* Content */}
            <div className="p-4">
              {tabsData.tabs.map(
                (tab) =>
                  activeTab === tab.id && (
                    <div key={tab.id}>
                      {tab.bdssyllabus &&
                        (isMobile ? (
                          <button
                            onClick={() => openPDF(department_bds)}
                            className="px-6 py-3 bg-[#00B4AC]/10 hover:bg-[#00B4AC]/20 text-[#0352A1] rounded-lg transition-all duration-300 shadow-md hover:shadow-lg"
                          >
                            BDS Syllabus? Please Click here
                          </button>
                        ) : (
                          <div className="flex justify-center">
                            <div className="w-full">
                              <div className="p-4 bg-white rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.1)] hover:shadow-[0_6px_15px_rgba(0,0,0,0.15)] transition-all duration-300">
                                <iframe
                                  src={department_bds}
                                  title="BDS Syllabus"
                                  className="w-full h-[800px] rounded-lg bg-white border-none"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      {tab.mdssyllabus &&
                        (isMobile ? (
                          <button
                            onClick={() => openPDF(department_mds)}
                            className="px-6 py-3 bg-[#00B4AC]/10 hover:bg-[#00B4AC]/20 text-[#0352A1] rounded-lg transition-all duration-300 shadow-md hover:shadow-lg"
                          >
                            MDS Syllabus? Please Click here
                          </button>
                        ) : (
                          <div className="flex justify-center">
                            <div className="w-full">
                              <div className="p-4 bg-white rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.1)] hover:shadow-[0_6px_15px_rgba(0,0,0,0.15)] transition-all duration-300">
                                <iframe
                                  src={department_mds}
                                  title="MDS Syllabus"
                                  className="w-full h-[800px] rounded-lg bg-white border-none"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepartmentSyllabus;
