import React, { useState, useCallback, useEffect } from 'react';
import { Hourglass } from 'react-loader-spinner';
import ImageViewer from 'react-simple-image-viewer';

const HostelGallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentFolder, setCurrentFolder] = useState('main');

  const galleryStructure = {
    main: [
      {
        id: 'bih',
        type: 'folder',
        name: 'Bapuji International Hostel',
        thumbnail: '/assets/hostel/bih/thumbnail.webp'
      },
      {
        id: 'bihgirls',
        type: 'folder',
        name: 'Bapuji International Hostel for Girls',
        thumbnail: '/assets/hostel/bihgirls/thumbnail.webp'
      },
      {
        id: 'boys',
        type: 'folder',
        name: 'Boys Hostel',
        thumbnail: '/assets/hostel/boys/thumbnail.webp'
      },
      {
        id: 'ladies',
        type: 'folder',
        name: 'Ladies Hostel',
        thumbnail: '/assets/hostel/ladies/thumbnail.webp'
      }
    ],
    bih: [
      {
        src: '/assets/hostel/BIH/1 INTERNATIONAL HOSTEL ENTRANCE.webp',
        alt: 'INTERNATIONAL HOSTEL ENTRANCE'
      },
      { src: '/assets/hostel/BIH/2 VISITORS ROOM.webp', alt: 'VISITORS ROOM' },
      { src: '/assets/hostel/BIH/3 DINING HALL.webp', alt: 'DINING HALL' },
      { src: '/assets/hostel/BIH/3a DINING AREA.webp', alt: 'DINING AREA' },
      { src: '/assets/hostel/BIH/3b DINING AREA.webp', alt: 'DINING AREA2' },
      { src: '/assets/hostel/BIH/4 ROOM.webp', alt: 'ROOM' },
      { src: '/assets/hostel/BIH/4a ROOM.webp', alt: 'ROOM' },
      { src: '/assets/hostel/BIH/5 WIFI FACILITY.webp', alt: 'WIFI FACILITY' },
      { src: '/assets/hostel/BIH/6 WASHING AREA.webp', alt: 'WASHING AREAa' },
      { src: '/assets/hostel/BIH/7 AERIAL VIEW OF HOSTEL.webp', alt: 'AERIAL VIEW OF HOSTEL' }
    ],
    bihgirls: [
      {
        src: '/assets/hostel/BIHforGirls/1 GIRLS HOSTEL ENTRANCE.webp',
        alt: 'GIRLS HOSTEL ENTRANCE'
      },
      {
        src: '/assets/hostel/BIHforGirls/2 INDOOR SPORTS ROOM.webp',
        alt: 'INDOOR SPORTS ROOM'
      },
      {
        src: '/assets/hostel/BIHforGirls/2a INDOOR SPORTS ROOM.webp',
        alt: 'INDOOR SPORTS ROOM'
      },
      {
        src: '/assets/hostel/BIHforGirls/3 ROOM.webp',
        alt: 'ROOM'
      },
      {
        src: '/assets/hostel/BIHforGirls/3 TV ROOM.webp',
        alt: 'TV ROOM'
      },
      {
        src: '/assets/hostel/BIHforGirls/4 WIFI FACILITY.webp',
        alt: 'WIFI FACILITY'
      },
      {
        src: '/assets/hostel/BIHforGirls/5 GYMNASIUM.webp',
        alt: 'GYMNASIUM'
      },
      {
        src: '/assets/hostel/BIHforGirls/5a GYMNASIUM.webp',
        alt: 'GYMNASIUM'
      },
      {
        src: '/assets/hostel/BIHforGirls/6 VISITORS ROOM.webp',
        alt: 'VISITORS ROOM'
      },
      {
        src: '/assets/hostel/BIHforGirls/7 WASHING AREA.webp',
        alt: 'WASHING AREA'
      },
      {
        src: '/assets/hostel/BIHforGirls/8 DINING HALL.webp',
        alt: 'DINING HALL'
      }
    ],
    boys: [
      {
        src: '/assets/hostel/boysHostel/1 HOSTEL FRONT 1.webp',
        alt: 'HOSTEL FRONT 1'
      },
      {
        src: '/assets/hostel/boysHostel/2 HOSTEL NAME BOARD.webp',
        alt: 'HOSTEL NAME BOARD'
      },
      {
        src: '/assets/hostel/boysHostel/4 HOSTEL LIFT INSIDE.webp',
        alt: 'HOSTEL LIFT INSIDE'
      },
      {
        src: '/assets/hostel/boysHostel/5 HOSTEL DINING HALL.webp',
        alt: 'HOSTEL DINING HALL'
      },
      {
        src: '/assets/hostel/boysHostel/6 HOSTEL DINING HALL.webp',
        alt: 'HOSTEL DINING HALL'
      },
      {
        src: '/assets/hostel/boysHostel/7 HOSTEL GYM ROOM.webp',
        alt: 'HOSTEL GYM ROOM'
      },
      {
        src: '/assets/hostel/boysHostel/8 HOSTEL TT ROOM.webp',
        alt: 'HOSTEL TT ROOM'
      },
      {
        src: '/assets/hostel/boysHostel/9 HOSTEL TV ROOM.webp',
        alt: 'HOSTEL TV ROOM'
      }
    ],
    ladies: [
      {
        src: '/assets/hostel/LadiesHostel/1 Vedavathi Ladies Hostel.webp',
        alt: 'Vedavathi Ladies Hostel'
      },
      {
        src: '/assets/hostel/LadiesHostel/2 Hostel Lift.webp',
        alt: 'Hostel Lift'
      },
      {
        src: '/assets/hostel/LadiesHostel/3 Hostel Room..webp',
        alt: 'Hostel Room'
      },
      {
        src: '/assets/hostel/LadiesHostel/3A Hostel Room.webp',
        alt: 'Hostel Room'
      },
      {
        src: '/assets/hostel/LadiesHostel/4 Hostel Office.webp',
        alt: 'Hostel Office'
      },
      {
        src: '/assets/hostel/LadiesHostel/5 Hostel Office..webp',
        alt: 'Hostel Office'
      },
      {
        src: '/assets/hostel/LadiesHostel/6 Hostel Dining Hall..webp',
        alt: 'Hostel Dining Hall'
      },
      {
        src: '/assets/hostel/LadiesHostel/7 Hostel TV Room.webp',
        alt: 'Hostel TV Room'
      },
      {
        src: '/assets/hostel/LadiesHostel/8 Netravathi Ladies Hostel Entrance.webp',
        alt: 'Netravathi Ladies Hostel Entrance'
      },
      {
        src: '/assets/hostel/LadiesHostel/8A Netravathi Ladies Hostel..webp',
        alt: 'Netravathi Ladies Hostel'
      },
      {
        src: '/assets/hostel/LadiesHostel/9 Vedavathi Ladies Hostel Entrance.webp',
        alt: 'Vedavathi Ladies Hostel Entrance'
      },
      {
        src: '/assets/hostel/LadiesHostel/10 Vehicle Parking Area.webp',
        alt: 'Vehicle Parking Area'
      }
    ]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openImageViewer = useCallback((images, index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const navigateToFolder = (folderId) => {
    setCurrentFolder(folderId);
  };

  const navigateBack = () => {
    setCurrentFolder('main');
  };

  const renderContent = () => {
    const currentContent = galleryStructure[currentFolder];
    const isMainFolder = currentFolder === 'main';

    return (
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
        {!isMainFolder && (
          <div className="flex justify-center cursor-pointer" onClick={navigateBack}>
            <div className="w-[320px] overflow-hidden rounded-xl shadow-lg hover:scale-105 hover:shadow-xl transition-all duration-300">
              <div className="h-[200px] w-full bg-gradient-to-br from-blue-50 to-gray-100 flex flex-col items-center justify-center gap-2">
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-[#0352A1]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                  <span className="text-xl text-[#0352A1] font-garamond">Back to Gallery</span>
                </div>
                <div className="text-lg text-[#00B4AC] font-garamond font-medium">
                  Currently in:{' '}
                  {galleryStructure.main.find((folder) => folder.id === currentFolder)?.name}
                </div>
              </div>
            </div>
          </div>
        )}

        {currentContent.map((item, index) => (
          <div key={index} className="flex justify-center">
            <div
              className="w-[320px] overflow-hidden rounded-xl shadow-lg hover:scale-105 hover:shadow-xl transition-all duration-300"
              onClick={() =>
                item.type === 'folder'
                  ? navigateToFolder(item.id)
                  : openImageViewer(currentContent, index)
              }
            >
              <div className="relative h-[200px] w-full">
                {item.type === 'folder' ? (
                  <div className="absolute inset-0 bg-gradient-to-br from-[#0352A1]/5 to-[#00B4AC]/10 flex flex-col items-center justify-center p-4">
                    <div className="p-4 mb-4 rounded-full bg-white/90">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-12 w-12 text-[#0352A1]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                        />
                      </svg>
                    </div>
                    <span className="text-xl font-garamond text-[#0352A1] text-center font-semibold">
                      {item.name}
                    </span>
                  </div>
                ) : (
                  <img
                    src={item.src}
                    alt={item.alt}
                    className="absolute inset-0 object-cover w-full h-full cursor-pointer"
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="container px-4 py-8 mx-auto ">
      <h2 className="text-3xl font-garamond text-center mb-8 text-[#0352A1]">Hostel Gallery</h2>

      <div className="w-full">
        {loading && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-white/80">
            <Hourglass visible={true} height="80" width="80" colors={['#97905E', '#000000']} />
          </div>
        )}

        {renderContent()}

        {isViewerOpen && (
          <div className="fixed inset-0 z-50">
            <ImageViewer
              src={galleryStructure[currentFolder].map((item) => item.src)}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HostelGallery;
