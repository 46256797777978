import React, { useRef, useEffect, useState } from 'react';

const AnimatedDivider = ({ message }) => {
  const messageRef = useRef(null);
  const [dividerWidth, setDividerWidth] = useState('auto');

  useEffect(() => {
    if (messageRef.current) {
      setDividerWidth(`${messageRef.current.offsetWidth + 100}px`);
    }
  }, [message]);

  return (
    <div className="flex flex-col items-center w-full mb-4 mb-10 space-y-0">
      <h1
        ref={messageRef}
        className="text-4xl font-bold tracking-wide text-gray-800 uppercase md:text-4xl font-ebgaramond"
      >
        {message}
      </h1>
      {/* <span className="inline-block pb-1 border-b-2 border-gray-300">{message}</span> */}

      <div className="relative flex items-center justify-center" style={{ width: dividerWidth }}>
        <div className="absolute inset-0 h-[2px] bg-gray-300"></div>
        <div
          className="absolute left-0 w-4 h-4 bg-gray-300 rounded-full"
          style={{
            animation: 'expand-shrink 1.5s infinite ease-in-out'
          }}
        ></div>
        <div
          className="absolute right-0 w-4 h-4 bg-gray-300 rounded-full"
          style={{
            animation: 'expand-shrink 1.5s infinite ease-in-out',
            animationDelay: '0.5s'
          }}
        ></div>
        <div className="absolute flex items-center justify-between w-full">
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              key={index}
              className="w-3 h-3 bg-gray-300 rounded-full"
              style={{
                animation: `wave 2s infinite ease-in-out`,
                animationDelay: `${index * 0.3}s`
              }}
            ></div>
          ))}
        </div>
      </div>

      <style>
        {`
        /* Expanding and Shrinking Dots */
        @keyframes expand-shrink {
          0%, 100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.5);
          }
        }

        /* Wave Animation for Dots */
        @keyframes wave {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
        }
      `}
      </style>
    </div>
  );
};

export default AnimatedDivider;
