import { useTheme } from '@emotion/react';
import { Divider, Grid, Typography, useMediaQuery, Button } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import departmentData from './departmentData.json';
import DepNameBar from './DepNameBar';
import DepButtons from './DepButtons';
import { fetchPublicationsByDept } from '../BackOfiice/components/UtilityFunctions';
import BDCH_GIF from 'assets/BDCH_GIF.gif';

import { useEffect } from 'react';
import AnimatedDivider from './DeptHeading';

function Publications() {
  const { id } = useParams();
  const department = departmentData[id];
  const [publications, setPublications] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPublicationsByDept(id, setPublications, setLoading);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log('publications', publications);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const openPDF = (pdfUrl) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <img src={BDCH_GIF} alt="Loading..." />
      </div>
    );
  }

  return (
    <div className={`mx-auto ${isMobile ? 'mt-[8vh]' : 'mt-[28vh]'}`}>
      {isMobile ? <DepNameBar id={id} department={department} /> : null}

      {!isMobile && <AnimatedDivider message={`${department?.name} Publications`} />}

      <div className="flex flex-wrap justify-center pt-10">
        {!isMobile && (
          <div className="w-full pr-5 md:w-1/6">
            <DepButtons id={id} />
          </div>
        )}

        {publications &&
          (isMobile ? (
            <button
              onClick={() => openPDF(publications)}
              className="px-6 py-3 bg-[#00B4AC]/10 hover:bg-[#00B4AC]/20 text-[#0352A1] rounded-lg transition-all duration-300 shadow-md hover:shadow-lg"
            >
              Publications? Please Click here
            </button>
          ) : (
            <div className="w-full md:w-2/3">
              <div className="p-4 bg-white rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.1)] hover:shadow-[0_6px_15px_rgba(0,0,0,0.15)] transition-all duration-300">
                <iframe
                  title="Publications PDF"
                  src={publications}
                  className="w-full h-[800px] rounded-lg bg-white border-none"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Publications;
