import React, { useState, useCallback, useEffect } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { Hourglass } from 'react-loader-spinner';
import imageData from './libimagegallerydata.json';

function ImageGallery() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [galleryData] = useState(imageData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="container px-4 py-8 mx-auto">
      <div className="w-full">
        {loading && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-white/80">
            <Hourglass visible={true} height="80" width="80" colors={['#97905E', '#000000']} />
          </div>
        )}

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
          {galleryData.map((image, index) => (
            <div key={index} className="flex justify-center">
              <div
                className="w-[320px] overflow-hidden rounded-xl shadow-lg hover:scale-105 hover:shadow-xl transition-all duration-300 cursor-pointer"
                onClick={() => openImageViewer(index)}
              >
                <div className="relative h-[200px] w-full">
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="absolute inset-0 w-full h-full object-fit"
                  />
                </div>
                <div className="p-3 font-garamond font-medium text-center bg-gradient-to-r from-[#0352A1]/5 to-[#00B4AC]/10">
                  <p className="text-[#0352A1] font-semibold truncate">{image.alt}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {isViewerOpen && (
          <div className="fixed inset-0 z-50">
            <ImageViewer
              src={galleryData.map((item) => item.src)}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageGallery;
