import { Divider, Grid, Typography } from '@mui/material';
import { viewNews } from 'api-ws/news-ws';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './moving-sentence.css';
// import { Marquee } from '@devnomic/marquee';
// import '@devnomic/marquee/dist/index.css';
function VerticalNewsBanner() {
  const navigate = useNavigate();
  const [newsItems, setNewsItems] = useState([]);

  const fetchNews = () => {
    viewNews()
      .then((res) => {
        const titlesArray = res.data.data.map((item) => item.title);
        setNewsItems(titlesArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const handleViewMoreClick = () => {
    navigate('/news');
  };

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '30vh',
          width: '80%',
          textAlign: 'center'
        }}
      >
        <Typography style={{ fontSize: '28px' }}>News and Events</Typography>
        <Divider
          sx={{
            backgroundColor: 'black',
            height: '2px',
            width: '80%'
          }}
        />
        <Grid item xs={12}>
          {/* <Marquee
            fade={true}
            reverse={false}
            style={{
              marginTop: '50px',
              width: '400px',
              height: '300px',
              overflow: 'hidden'
            }}
            direction="up"
            gradient={false}
            pauseOnHover={true}
          >
            <div style={{ width: '100%', marginBottom: '100px' }}>
              {newsItems.map((news, index) => (
                <div key={index} style={{ margin: '5px 0' }}>
                  <Typography
                    style={{
                      whiteSpace: 'normal',
                      overflow: 'hidden',
                      height: 'auto'
                    }}
                  >
                    ➢ {news}
                  </Typography>

                  <span
                    style={{
                      color: 'blue',
                      cursor: 'pointer',
                      display: 'block',
                      marginTop: '5px'
                    }}
                    onClick={(e) => {
                      handleViewMoreClick();
                    }}
                  >
                    ...View more
                  </span>
                </div>
              ))}
            </div>
          </Marquee> */}
        </Grid>
      </Grid>
    </>
  );
}

export default VerticalNewsBanner;
