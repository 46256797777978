import React, { useState, useEffect } from 'react';

const ImageCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const slides = [
    {
      url: '/assets/Homepagecarousel/1 College Photo.webp',
      title: 'Slide 1',
      alt: 'Description of slide 1'
    },
    {
      url: '/assets/Homepagecarousel/0450  jain studio  040809.webp',
      title: 'Slide 2',
      alt: 'Description of slide 2'
    },
    {
      url: '/assets/Homepagecarousel/0451  jain studio  040809.webp',
      title: 'Slide 3',
      alt: 'Description of slide 3'
    },
    {
      url: '/assets/Homepagecarousel/IMG_5152.webp',
      title: 'Slide 3',
      alt: 'Description of slide 3'
    },
    {
      url: '/assets/Homepagecarousel/IMG_5153.webp',
      title: 'Slide 3',
      alt: 'Description of slide 3'
    },
    {
      url: '/assets/Homepagecarousel/IMG_5154.webp',
      title: 'Slide 3',
      alt: 'Description of slide 3'
    },
    {
      url: '/assets/Homepagecarousel/IMG_5157.webp',
      title: 'Slide 3',
      alt: 'Description of slide 3'
    },
    {
      url: '/assets/Homepagecarousel/IMG_5160.webp',
      title: 'Slide 3',
      alt: 'Description of slide 3'
    },
    {
      url: '/assets/Homepagecarousel/IMG_5161.webp',
      title: 'Slide 3',
      alt: 'Description of slide 3'
    },
    {
      url: '/assets/Homepagecarousel/IMG_5162.webp',
      title: 'Slide 3',
      alt: 'Description of slide 3'
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
        setFadeIn(true);
      }, 500);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const prevSlide = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
      setFadeIn(true);
    }, 500);
  };

  const nextSlide = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
      setFadeIn(true);
    }, 500);
  };

  return (
    <div className="w-full h-[600px] relative group">
      <div
        style={{
          backgroundImage: `url("${slides[currentSlide].url}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
        className={`w-full h-full transition-opacity duration-1000 ease-in-out ${
          fadeIn ? 'opacity-100' : 'opacity-0'
        }`}
        role="img"
        aria-label={slides[currentSlide].alt}
      />

      {/* Navigation Arrows */}
      <button
        onClick={prevSlide}
        className="absolute hidden p-4 text-2xl text-white transition-all -translate-y-1/2 rounded-full cursor-pointer group-hover:block top-1/2 left-5 bg-black/50 hover:bg-black/70"
        aria-label="Previous slide"
      >
        ←
      </button>

      <button
        onClick={nextSlide}
        className="absolute hidden p-4 text-2xl text-white transition-all -translate-y-1/2 rounded-full cursor-pointer group-hover:block top-1/2 right-5 bg-black/50 hover:bg-black/70"
        aria-label="Next slide"
      >
        →
      </button>

      {/* Navigation Dots */}
      <div className="absolute left-0 right-0 bottom-4">
        <div className="flex justify-center py-2">
          {slides.map((_, idx) => (
            <button
              key={idx}
              onClick={() => setCurrentSlide(idx)}
              className={`h-3 w-3 mx-2 rounded-full transition-all ${
                currentSlide === idx ? 'bg-white scale-125' : 'bg-white/50 hover:bg-white/75'
              }`}
              aria-label={`Go to slide ${idx + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
