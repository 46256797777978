import { Grid, Typography } from '@mui/material';
import { viewNews } from 'api-ws/news-ws';
import React, { useEffect, useState } from 'react';
// import { Marquee } from '@devnomic/marquee';
// import '@devnomic/marquee/dist/index.css';
import { viewAlumniNews } from 'api-ws/alumniNews-ws';
import { useNavigate } from 'react-router-dom';
import newgif from './newgif.gif';

function HorizontalNews({ colorConfig, alumninews, setIsLoading }) {
  const { textColor } = colorConfig;
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Get navigate function
  const fetchNews = () => {
    setIsLoading(true);
    if (alumninews) {
      setLoading(true);
      viewAlumniNews()
        .then((res) => {
          setLoading(false);
          setIsLoading(false);
          // setNews(res.data.alumniNews);
          const sortedNews = res.data.alumniNews.sort((a, b) =>
            a.priority === b.priority ? 0 : a.priority ? -1 : 1
          );

          setNews(sortedNews);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      viewNews()
        .then((res) => {
          setLoading(false);
          setIsLoading(false);
          const sortedNews = res.data.data.sort((a, b) =>
            a.priority === b.priority ? 0 : a.priority ? -1 : 1
          );

          setNews(sortedNews);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    fetchNews();
  }, [alumninews]);

  const handleViewMoreClick = () => {
    if (alumninews) {
      navigate('/alumni/news');
    } else {
      navigate('/news');
    }
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        backgroundColor: alumninews ? '' : textColor,
        width: '100%',
        height: '10vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {/* <Loader loading={loading} /> */}
      <div className="w-full h-[10vh] bg-[textColor] overflow-hidden relative">
        <div className="whitespace-nowrap inline-block animate-[scroll_20s_linear_infinite] hover:pause">
          {news
            .filter((item) => item?.priority)
            .map((item, index) => (
              <div key={index} className="inline-flex items-center">
                <img src={newgif} alt="New Gif" width="40" height="20" className="ml-4" />
                <Typography
                  variant="h6"
                  className={`inline-flex items-center mr-16 ${
                    alumninews ? 'text-gray-800' : 'text-gray-100'
                  }`}
                >
                  {item?.title}
                  <span
                    onClick={handleViewMoreClick}
                    className="ml-2 text-sm text-gray-400 transition-colors duration-200 cursor-pointer hover:text-blue-500"
                  >
                    ...View more
                  </span>
                </Typography>
              </div>
            ))}
        </div>
      </div>
    </Grid>
  );
}

export default HorizontalNews;
