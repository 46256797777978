import React, { useEffect, useState } from 'react';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import AHeader from '../components/AHeader';

const Examschedule = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className="min-h-screen bg-transparent">
      <AHeader header={'EXAM SCHEDULE'} />

      <div className={`container mx-auto px-4 py-8 ${isMobile ? 'max-w-full' : 'max-w-7xl'}`}>
        <div className="flex flex-col gap-5 md:flex-row">
          {/* Main Content */}
          <div className="w-full md:w-3/4">
            {/* BDS Schedule Card */}
            <div className="mb-5">
              <div className="overflow-hidden transition-transform duration-300 bg-white shadow-lg rounded-xl hover:shadow-xl">
                <div className="p-6">
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td className="pb-3">
                          <h2 className="text-xl md:text-2xl font-garamond text-[#0352A1] font-medium">
                            BDS Exam Schedule - Tentative
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="text-lg text-[#0352A1]/80 italic font-garamond">
                            August/September & March/April
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* MDS Schedule Card */}
            <div className="mb-5">
              <div className="overflow-hidden transition-transform duration-300 bg-white shadow-lg rounded-xl hover:shadow-xl">
                <div className="p-6">
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td className="pb-3">
                          <h2 className="text-xl md:text-2xl font-garamond text-[#0352A1] font-medium">
                            MDS Exam Schedule - Tentative
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="text-lg text-[#0352A1]/80 italic font-garamond">
                            July / December
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* News Banner */}
          {!isMobile && (
            <div className="w-full md:w-1/4">
              <VerticalNewsBanner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Examschedule;
