import React, { useState, useEffect } from 'react';
import Overview from './Overview';
import Collections from './Collections';
import Services from './Services';
import AHeader from '../components/AHeader';
import ImageGallery from './libimagegallery';

const LibraryPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabsData = {
    tabs: [
      {
        id: 0,
        label: 'Overview',
        overview: {
          heading: 'ADMISSION PROCEDURE FOR BDS & MDS COURSES'
        }
      },
      {
        id: 1,
        label: 'Collections',
        collections: {
          heading: 'af'
        }
      },
      {
        id: 2,
        label: 'Facilities and Services',
        services: {
          heading: 'kc'
        }
      },
      {
        id: 3,
        label: 'Image Gallery',
        imagegallery: {
          heading: 'kc'
        }
      }
    ]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <AHeader header={'LIBRARY'} />

      <div className="container px-4 py-8 mx-auto">
        <div className="overflow-hidden shadow-lg rounded-xl">
          {/* Tabs Navigation */}
          <div className="flex flex-col border-b border-gray-200 sm:flex-row">
            {tabsData.tabs.map((tab, index) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  relative w-full px-6 py-4 text-base font-medium font-garamond
                  transition-all duration-200
                  ${
                    activeTab === tab.id
                      ? `
                        text-[#0180A6] bg-blue-50/50
                        before:absolute before:left-0 before:top-0 before:bottom-0 
                        before:w-1 sm:before:w-0 before:bg-[#0180A6]
                        sm:border-b-4 sm:border-[#0180A6]
                        ${index === 0 ? 'sm:rounded-tl-xl' : ''}
                        ${index === tabsData.tabs.length - 1 ? 'sm:rounded-tr-xl' : ''}
                        rounded-r-xl sm:rounded-r-none
                      `
                      : `
                        text-gray-500 hover:text-[#0180A6] hover:bg-blue-50/30
                        before:absolute before:left-0 before:top-0 before:bottom-0 
                        before:w-1 sm:before:w-0 before:bg-transparent
                        sm:border-b-4 sm:border-transparent
                      `
                  }
                `}
              >
                {tab.label}
              </button>
            ))}
          </div>

          {/* Tab Content */}
          <div className="p-6">
            {tabsData.tabs.map(
              (tab) =>
                activeTab === tab.id && (
                  <div key={tab.id} className="space-y-6 animate-fadeIn">
                    {tab.overview && (
                      <div className="flex flex-col">
                        <Overview overviewData={tab.overview} />
                      </div>
                    )}
                    {tab.collections && (
                      <div className="flex flex-col">
                        <Collections collectionsData={tab.collections} />
                      </div>
                    )}
                    {tab.services && (
                      <div className="flex flex-col">
                        <Services servicesData={tab.services} />
                      </div>
                    )}
                    {tab.imagegallery && (
                      <div className="flex flex-col">
                        <ImageGallery imageGalleryData={tab.imagegallery} />
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryPage;
