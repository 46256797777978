import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import departmentData from './departmentData.json';
import DepNameBar from './DepNameBar';
import DepButtons from './DepButtons';
import ImageViewer from 'react-simple-image-viewer';
import { Hourglass } from 'react-loader-spinner';
import AnimatedDivider from './DeptHeading';

function Activities() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const srcArray = departmentData[id]?.Activity?.map((item) => item.img_src);
  const department = departmentData[id];
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  if (!department) return null;

  return (
    <div className={` mx-auto ${isMobile ? 'mt-[7vh]' : 'mt-[25vh]'}`}>
      {isMobile ? <DepNameBar id={id} department={department} /> : null}

      {!isMobile && <AnimatedDivider message={`${department?.name} Activities`} />}

      <div className="flex flex-wrap justify-center pt-10">
        {!isMobile && (
          <div className="w-full pr-5 md:w-1/6">
            <DepButtons id={id} />
          </div>
        )}

        <div className="w-full md:w-2/3">
          {loading && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-white/80">
              <Hourglass visible={true} height="80" width="80" colors={['#97905E', '#000000']} />
            </div>
          )}

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            {department?.Activity?.map((image, index) => (
              <div
                key={index}
                className="flex justify-center rounded-lg bg-[#00B4AC]/10 hover:bg-[#00B4AC]/20 hover:scale-105 hover:shadow-xl transition-all duration-300"
              >
                <div className="w-full h-full overflow-hidden rounded-lg shadow-lg">
                  <div className="relative pb-[75%] w-full">
                    <img
                      src={image.img_src}
                      alt={image.title}
                      onClick={() => openImageViewer(index)}
                      className="absolute inset-0 object-cover w-full h-full cursor-pointer"
                    />
                  </div>
                  <div className="p-2 text-center">
                    <p className="text-[#0352A1] font-ebgaramond font-medium">{image.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {isViewerOpen && (
            <div className="fixed inset-0 z-50">
              <ImageViewer
                src={srcArray}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Activities;
