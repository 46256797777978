import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material';

const Boyshostel = () => {
  const boyshostelData = [
    {
      name: 'BAPUJI INTERNATIONAL HOSTEL FOR BOYS',
      totalRooms: 140,
      capacity: 230,
      telephone: '91-8192-224611, 8892534447',
      recreation:
        'Newspapers, Magazines, T.V., Gymnasium, Table Tennis, Caroms, Chess, Shuttle, Lift Facilities',
      mess: 'Veg / Non veg.',
      warden: 'Dr. Baron Tarun Kumar A.'
    },
    {
      name: "DENTAL COLLEGES' BOYS HOSTEL",
      totalRooms: 260,
      capacity: '178 - Twin Sharing & 82 - Single Occupancy',
      telephone: '91-8192-220478',
      recreation:
        'Newspapers, Magazines, T.V., Gymnasium, Table Tennis, Caroms, Chess, Shuttle, Lift Facilities',
      mess: 'Veg / Non veg.',
      warden: 'Dr. B. H. Dhanya Kumar'
    }
  ];

  return (
    <div className="container px-4 mx-auto">
      <div className="mt-6 mb-5">
        <h1 className="mb-6 text-2xl font-semibold text-center md:text-3xl font-ebgaramond">
          Boys Hostel Information
        </h1>

        <div className="overflow-x-auto">
          <div className="min-w-[320px]">
            {boyshostelData.map((hostel, index) => (
              <div
                key={index}
                className="mb-12 overflow-hidden bg-white rounded-xl shadow-[0_10px_20px_rgba(0,0,0,0.15)] transition-all duration-300 border border-gray-100"
              >
                {/* Mobile View */}
                <div className="block md:hidden">
                  <div className="bg-[#0180A6] text-white p-5 font-ebgaramond text-xl font-bold flex items-center justify-center min-h-[100px] text-center">
                    {hostel.name}
                  </div>
                  <div className="p-4 space-y-3">
                    <p className="text-sm pl-2.5">
                      <span className="font-bold">Total Rooms:</span> {hostel.totalRooms}
                    </p>
                    <p className="text-sm pl-2.5">
                      <span className="font-bold">Maximum Capacity:</span> {hostel.capacity}
                    </p>
                    <p className="text-sm pl-2.5">
                      <span className="font-bold">Telephone Nos:</span> {hostel.telephone}
                    </p>
                    <p className="text-sm pl-2.5 break-words">
                      <span className="font-bold">Recreation Facilities:</span> {hostel.recreation}
                    </p>
                    <p className="text-sm pl-2.5">
                      <span className="font-bold">Mess Facility:</span> {hostel.mess}
                    </p>
                    <p className="text-sm pl-2.5">
                      <span className="font-bold">Warden:</span> {hostel.warden}
                    </p>
                  </div>
                </div>

                {/* Desktop View */}
                <div className="hidden md:grid md:grid-cols-[300px_1fr] border border-gray-200">
                  <div className="bg-[#0180A6] text-white p-5 font-ebgaramond text-xl font-bold flex items-center justify-center min-h-[100px] text-center">
                    {hostel.name}
                  </div>
                  <div className="p-4 space-y-3">
                    <p className="text-base pl-2.5">
                      <span className="font-bold">Total Rooms:</span> {hostel.totalRooms}
                    </p>
                    <p className="text-base pl-2.5">
                      <span className="font-bold">Maximum Capacity:</span> {hostel.capacity}
                    </p>
                    <p className="text-base pl-2.5">
                      <span className="font-bold">Telephone Nos:</span> {hostel.telephone}
                    </p>
                    <p className="text-base pl-2.5 break-words">
                      <span className="font-bold">Recreation Facilities:</span> {hostel.recreation}
                    </p>
                    <p className="text-base pl-2.5">
                      <span className="font-bold">Mess Facility:</span> {hostel.mess}
                    </p>
                    <p className="text-base pl-2.5">
                      <span className="font-bold">Warden:</span> {hostel.warden}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boyshostel;
