import {
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Divider,
  useTheme,
  useMediaQuery,
  styled
} from '@mui/material';
import React, { useState } from 'react';
import RoomIcon from '@mui/icons-material/Room';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import { addBdchContact } from 'api-ws/bdchContact-ws';
import { Email } from '@mui/icons-material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import AHeader from '../components/AHeader';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px'
}));

function Contact() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  const messageSubmit = () => {
    console.log('hello');
    const messageRequest = {
      name: name,
      email: email,
      message: message
    };
    addBdchContact(messageRequest)
      .then((res) => {
        console.log('Message sent successfully:', res);
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((err) => console.log('Error sending message:', err));
  };

  const contactData = [
    {
      name: 'Dr. D.B. Nandeeshwar',
      position: 'Principal',
      phone: '08192-220572'
    },
    {
      name: 'Dr. Ashok L.',
      position: 'Vice-Principal',
      phone: '08192-220575'
    },
    {
      name: 'Mrs. Sandhya Rani P.M.',
      position: 'Manager',
      phone: '08192-220573'
    }
  ];

  return (
    <Grid style={{ marginTop: isMobile ? '8vh' : '25vh' }}>
      <AHeader header={' CONNECT WITH US'} />

      <Grid
        container
        spacing={2}
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          paddingLeft: '20px',
          alignContent: 'center'
        }}
      >
        {/* Left side (30%) */}
        <Grid item xs={12} md={6}>
          <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom style={{ fontFamily: "'EB Garamond', serif" }}>
                Address
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <Typography variant="body1" style={{ fontFamily: 'Lora, serif' }}>
                    The Principal
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <RoomIcon />
                  </ListItemIcon>
                  <Typography variant="body1" style={{ fontFamily: 'Lora, serif' }}>
                    Bapuji Dental College & Hospital
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <RoomIcon />
                  </ListItemIcon>
                  <Typography variant="body1" style={{ fontFamily: 'Lora, serif' }}>
                    Post Box No.326, DAVANGERE – 577004.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <RoomIcon />
                  </ListItemIcon>
                  <Typography variant="body1" style={{ fontFamily: 'Lora, serif' }}>
                    Karnataka, India
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MailOutlineIcon />
                  </ListItemIcon>
                  <Typography variant="body1" style={{ fontFamily: 'Lora, serif' }}>
                    principal@bapujidental.edu
                  </Typography>
                </ListItem>
              </List>
            </CardContent>
          </Card>
          <Card
            style={{
              marginTop: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              paddingBottom: '3vh'
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom style={{ fontFamily: "'EB Garamond', serif" }}>
                Contact Information
              </Typography>

              <Grid container spacing={3}>
                {contactData.map((contact, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <List>
                      <ListItem>
                        <Typography variant="body1" style={{ fontFamily: 'Lora, serif' }}>
                          {contact.name}
                          <br />
                          {contact.position}
                          <br />
                          {contact.phone}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Right side (70%) */}
        <Grid item xs={12} md={6} style={{ paddingRight: '20px' }}>
          <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom style={{ fontFamily: "'EB Garamond', serif" }}>
                Write a Message
              </Typography>
              <ValidatorForm onSubmit={messageSubmit} onError={() => null}>
                <TextField
                  label="Name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  errorMessages="Name is Required"
                  validators={['required']}
                />
                <TextField
                  label="Email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  errorMessages="Email is Required"
                  validators={['required']}
                />
                <TextField
                  label="Message"
                  multiline
                  rows={7}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  errorMessages={['Message is Required']}
                  validators={['required']}
                />
                <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      fontSize: '14px',
                      backgroundColor: '#00B4AC',
                      fontFamily: 'Lora, serif'
                    }}
                    onClick={messageSubmit}
                  >
                    Send Message
                  </Button>
                </Grid>
              </ValidatorForm>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <div style={{ margin: '0 20px', marginTop: '20px' }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.5442066055552!2d75.91392931432863!3d14.453411184542869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba2571eb98f1fd%3A0x1e9b7635c879caac!2sBapuji+Dental+College+and+Hospital!5e0!3m2!1sen!2sin!4v1506588013103"
          title="Maps Location"
          width="100%"
          height="350"
          frameborder="20px"
          allowfullscreen=""
        ></iframe>
      </div>
    </Grid>
  );
}

export default Contact;
