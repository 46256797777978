import * as React from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import logo from '../components/baplogo.png';

export default function Footer() {
  return (
    <footer className="bg-[#0180A6] py-6 mt-10">
      <div className="mx-auto px-14">
        {/* Main Grid */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-4 font-lora">
          {/* Logo and Info */}
          <div className="flex flex-col justify-center h-full space-y-4 text-white sm:col-span-1">
            <div className="flex items-center justify-center sm:justify-start">
              <img src={logo} alt="Elite Logo" className="object-contain w-auto h-16" />
            </div>
            <p className="text-lg text-center sm:text-left font-ebgaramond">
              45 Glorious Years of Excellence
            </p>
          </div>

          {/* Other Sections */}
          <div className="grid grid-cols-1 gap-6 sm:col-span-3 md:grid-cols-3">
            {/* Quick Links */}
            <div className="flex flex-col space-y-3 text-white font-lora">
              <h3 className="mb-2 text-lg font-bold font-ebgaramond">Quick Links</h3>
              <div>
                <Link to="/" className="hover:underline">
                  Home
                </Link>
              </div>
              <div>
                <Link to="/administration/management" className="hover:underline">
                  Administration
                </Link>
              </div>
              <div>
                <Link to="/academic-affairs/admission" className="hover:underline">
                  Admission
                </Link>
              </div>
              <div>
                <Link to="/news" className="hover:underline">
                  News and Events
                </Link>
              </div>
            </div>

            {/* Contact Us */}
            <div className="flex flex-col space-y-3 text-white">
              <h3 className="mb-2 text-lg font-bold font-ebgaramond">Contact Us</h3>
              <div>Principal: 08192220572</div>
              <div>Vice Principal: 08192220575</div>
              <div>Manager: 08192220573</div>
              <div>Email: principal@bapujidental.edu</div>
            </div>

            {/* Follow Us */}
            <div className="flex flex-col space-y-4 text-white">
              <h3 className="mb-2 text-lg font-bold font-ebgaramond">Follow Us</h3>
              <div className="flex space-x-4">
                <a
                  href="https://www.facebook.com/profile.php?id=100064657090851"
                  aria-label="Visit Facebook Profile"
                  className="hover:opacity-80"
                >
                  <img src="/assets/icons/facebookicon.svg" alt="Facebook" className="w-6 h-6" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  aria-label="Visit Instagram Profile"
                  className="hover:opacity-80"
                >
                  <img src="/assets/icons/instagramicon.svg" alt="Instagram" className="w-6 h-6" />
                </a>
                <a
                  href="https://www.twitter.com/"
                  aria-label="Visit Twitter Profile"
                  className="hover:opacity-80"
                >
                  <img src="/assets/icons/twitterx.svg" alt="Twitter" className="w-6 h-6" />
                </a>
                <a
                  href="https://www.youtube.com/user/bapujidentalcollege"
                  aria-label="Visit YouTube"
                  className="hover:opacity-80"
                >
                  <img src="/assets/icons/youtube.svg" alt="YouTube" className="w-6 h-6" />
                </a>
              </div>

              {/* QR Code */}
              <div className="mt-4">
                <div className="inline-block p-2 bg-white border border-gray-200 rounded-lg shadow-lg">
                  <QRCode
                    value="https://maps.app.goo.gl/DuhHL8g6U5Exkg2S6"
                    size={120}
                    className="hover:opacity-80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="flex flex-col justify-between mt-6 space-y-2 text-white sm:flex-row font-ebgaramond sm:space-y-0">
          <p>All Rights Reserved Bapuji Dental College & Hospital</p>
          <p>
            <a href="http://bytecrafts.in" className="hover:underline">
              Designed and Developed by ByteCrafts
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
