import pdf from './RTI.pdf';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AHeader from '../components/AHeader';

function Overview() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const iframeWidth = isMobile ? '90%' : '60%';
  const iframeHeight = isMobile ? '400px' : '800px';

  return (
    <div>
      <AHeader header={'Right To Information Act 2005'} />
      <div
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-start',
          width: '100%',
          boxSizing: 'border-box',
          paddingLeft: isMobile ? '0' : '300px',
          marginTop: '20px'
        }}
      >
        <div
          style={{
            width: isMobile ? '90%' : iframeWidth,
            overflowX: isMobile ? 'auto' : 'hidden',
            backgroundColor: 'white',
            padding: '15px',
            borderRadius: '0.75rem'
          }}
        >
          <iframe
            src={pdf}
            title="rti_pdf"
            width="100%"
            height={iframeHeight}
            style={{
              border: 'none',
              display: 'block'
            }}
            className="rounded-xl"
          />
        </div>
      </div>
    </div>
  );
}

export default Overview;
