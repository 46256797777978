import React from 'react';
import { Card, CardContent, Typography, useMediaQuery } from '@mui/material';

function CustomCard({ title, content }) {
  const isMobile = useMediaQuery('(max-width:400px)');
  return (
    <Card
      style={{
        minWidth: 275,
        maxWidth: 400,
        margin: 20,
        width: isMobile ? 325 : 350,
        height: 350,
        overflow: 'auto',
        marginLeft: isMobile ? '0px' : '50px'
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="h2"
          textAlign="center"
          style={{ color: '#750e21', fontFamily: "'EB Garamond', serif" }}
        >
          {title}
        </Typography>

        <Card
          style={{
            backgroundColor: '#dcdcdc',
            marginTop: 10,
            padding: '10px',
            height: 250,
            marginRight: isMobile ? '0px' : '10px'
          }}
        >
          <CardContent>
            <Typography
              variant="body2"
              component="p"
              style={{ color: 'black', textAlign: 'center' }}
            >
              {content}
            </Typography>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}

export default CustomCard;
