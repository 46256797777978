import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import DepButtons from "./DepButtons";
import DepNameBar from "./DepNameBar";
import departmentData from "./departmentData.json";
import { useParams } from "react-router-dom";
import CarosuelPage from "./carosuel";
import AnimatedDivider from "./DeptHeading";

const Overview = () => {
  const { id } = useParams();
  const department = departmentData[id];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [activeSections, setActiveSections] = useState(new Set());
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollingDown, setScrollingDown] = useState(true);

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const elementId = entry.target.id;
          if (entry.isIntersecting && scrollingDown) {
            entry.target.classList.add("opacity-100", "translate-y-0");
            entry.target.classList.remove("opacity-0", "translate-y-20");
            setActiveSections((prev) => new Set([...prev, elementId]));
          }
        });
      },
      { threshold: 0.2 }
    )
  );

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollingDown(currentScrollY > lastScrollY);
      setLastScrollY(currentScrollY);

      // Reset animations if the user scrolls back to the top
      if (currentScrollY === 0) {
        setActiveSections(new Set()); // Clear active sections
        const elements = document.querySelectorAll(".animate-on-scroll");
        elements.forEach((el) => {
          el.classList.add("opacity-0", "translate-y-20");
          el.classList.remove("opacity-100", "translate-y-0");
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  useEffect(() => {
    const elements = document.querySelectorAll(".animate-on-scroll");
    elements.forEach((el) => observer.current.observe(el));

    return () => {
      elements.forEach((el) => observer.current.unobserve(el));
    };
  }, []);

  if (!department) {
    return <div style={{ marginTop: "40vh" }}>Department not found</div>;
  }

  return (
    <div className={`mx-auto ${isMobile ? "mt-[8vh]" : "mt-[28vh]"}`}>
      {isMobile ? <DepNameBar id={id} department={department} /> : null}

      {!isMobile && <AnimatedDivider message={`${department?.name} Overview`} />}

      <div className="flex flex-wrap justify-center pt-10">
        {!isMobile && (
          <div className="w-full pr-5 md:w-1/6">
            <DepButtons id={id} />
          </div>
        )}

        <div className="w-full md:w-2/3">
          <div className="flex flex-col">
            <div className="w-full">
              {department?.image ? (
                <img
                  src={department.image}
                  alt="Department"
                  className="object-contain w-full h-auto"
                />
              ) : department?.carouselData ? (
                <CarosuelPage carouselData={department.carouselData} />
              ) : (
                <div>No image or carousel available</div>
              )}
            </div>

            <div className={`mt-5 ${isMobile ? "px-2.5" : ""}`}>
              {department?.overview?.map((item, index) => (
                <div
                  key={index}
                  id={`section-${index}`}
                  className="transition-all duration-700 ease-in-out translate-y-20 opacity-0 animate-on-scroll"
                >
                  <div className="mb-5 text-xl font-lora">
                    <div
                      className={`text-left ${item?.bold ? "font-bold" : ""
                        } text-[color:var(--big-text)]`}
                    >
                      {item?.heading}
                    </div>
                    <div
                      className={`text-justify font-lora text-lg ${item?.contentBold ? "font-bold" : ""
                        }`}
                      dangerouslySetInnerHTML={{ __html: item?.content }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
