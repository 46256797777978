import React, { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Grid, Typography, Divider, useMediaQuery, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import departmentData from './departmentData.json';
import DepNameBar from './DepNameBar';
import DepButtons from './DepButtons';
import { fetchAwardsByDept } from '../BackOfiice/components/UtilityFunctions';
import BDCH_GIF from 'assets/BDCH_GIF.gif';
import AnimatedDivider from './DeptHeading';

function Awards() {
  const { id } = useParams();
  const department = departmentData[id];
  const [awards, setAwards] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openPDF = (pdfUrl) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  useEffect(() => {
    fetchAwardsByDept(id, setAwards, setLoading);
  }, [id]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <img src={BDCH_GIF} alt="Loading..." />
      </div>
    );
  }

  return (
    <div className={`mx-auto ${isMobile ? 'mt-[8vh]' : 'mt-[28vh]'}`}>
      {isMobile ? <DepNameBar id={id} department={department} /> : null}

      {!isMobile && <AnimatedDivider message={`${department?.name} Awards`} />}

      <div className="flex flex-wrap justify-center pt-10">
        {!isMobile && (
          <div className="w-full pr-5 md:w-1/6">
            <DepButtons id={id} />
          </div>
        )}

        {awards &&
          (isMobile ? (
            <button
              onClick={() => openPDF(awards)}
              className="px-6 py-3 bg-[#00B4AC]/10 hover:bg-[#00B4AC]/20 text-[#0352A1] rounded-lg transition-all duration-300 shadow-md hover:shadow-lg"
            >
              Awards? Please Click here
            </button>
          ) : (
            <div className="w-full md:w-2/3">
              <div className="p-4 bg-white rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.1)] hover:shadow-[0_6px_15px_rgba(0,0,0,0.15)] transition-all duration-300">
                <iframe
                  title="Awards PDF"
                  src={awards}
                  className="w-full h-[800px] rounded-lg bg-white border-none"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Awards;
